import {
  IChangePassword,
  IForgotPassword,
  ILoginData,
  IRegisterData,
  IResetPassword,
  IVerify,
  IVerifyPassword,
} from '../../types'
export const AuthJSON = {
  login: ({email, password, phone, phoneCountry}: ILoginData) => {
    return {
      email: email,
      password: password,
      phoneCountry: phoneCountry,
      phone: phone,
    }
  },
  changePassword: ({newPassword, oldPassword}: IChangePassword) => {
    return {
      newPassword: newPassword,
      oldPassword: oldPassword,
    }
  },
  register: ({email, password, name, phone, phoneCountry, businessName}: IRegisterData) => {
    return {
      email: email,
      password: password,
      name: name,
      phone: phone,
      phoneCountry: phoneCountry,
      businessName: businessName,
    }
  },
  verify: ({code}: IVerify) => {
    return {code: code}
  },
  forgotPassword: ({email, phone, phoneCountry}: IForgotPassword) => {
    if (email) {
      return {
        email: email,
      }
    }
    if (phone && phoneCountry) {
      return {
        phoneCountry: phoneCountry,
        phone: phone,
      }
    }
  },
  verifyPassword: ({email, phone, phoneCountry, code}: IVerifyPassword) => {
    if (email) {
      return {
        email: email,
        code: code,
      }
    }
    if (phone && phoneCountry) {
      return {
        phoneCountry: phoneCountry,
        phone: phone,
        code: code,
      }
    }
  },
  resetPassword: ({email, phone, phoneCountry, code, newPassword}: IResetPassword) => {
    if (email) {
      return {
        email: email,
        code: code,
        newPassword: newPassword,
      }
    }
    if (phone && phoneCountry) {
      return {
        phoneCountry: phoneCountry,
        phone: phone,
        code: code,
        newPassword: newPassword,
      }
    }
  },
}
