import {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Dashboard} from '../../../../../utils/string'
import ContactUs from '../../../../modals/ContactUs'
// Media
import BrandLogo from '../../../../../_seller/assets/media/uTradeLogo.png'
import APICallService from '../../../../../api/apiCallService'
import {KycJson} from '../../../../../api/apiJSON/kyc'
import {AUTH, KYC} from '../../../../../api/apiEndPoints'
import {useAuth} from '../../core/Auth'
import {toast} from 'react-toastify'
import {kyc} from '../../../../../utils/toast'
import {Rejected} from '../../../../../utils/constants'
import {Button} from 'react-bootstrap'
const KycRejected = () => {
  const navigate = useNavigate()
  const {currentUser, saveCurrentUser} = useAuth()
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState<any>()
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  useEffect(() => {
    ;(async () => {
      await checkKycStatus()
    })()
  }, [])
  const checkKycStatus = async () => {
    let apiService = new APICallService(KYC.KYCSTATUSCHECK)
    let response = await apiService.callAPI()
    if (response) {
      let user: any = currentUser
      user = {
        ...user,
        business: {
          ...user.business,
          KYC: {
            ...user.business.KYC,
            profileUpdatedAt: response.business.KYC.profileUpdatedAt,
            status: response.business.KYC.status,
            statusMessage: response.business.KYC.statusMessage,
            statusUpdatedAt: response.business.KYC.statusUpdatedAt,
          },
        },
      }
      setStatus(response)
      saveCurrentUser(user)
      if (response.business.KYC.status !== Rejected) {
        navigate('/dashboard')
      }
    }
  }
  const sendFeedback = async () => {
    setLoading(true)
    let apiService = new APICallService(KYC.KYCFEEDBACK, KycJson.kycFeedback({message}), {
      id: currentUser?.business.profile,
    })
    let response = await apiService.callAPI()
    if (response) {
      toast.success(kyc.feedbackSent, {autoClose: 1000, theme: 'colored'})
    }
    setMessage('')
    setShow(false)
    setLoading(false)
  }
  const handleMessage = (value: string) => {
    setMessage(value)
  }
  const {logout} = useAuth()
  const [logoutLoading, setLogoutLoading] = useState(false)
  const handleLogout = async () => {
    setLogoutLoading(true)
    let apiService = new APICallService(AUTH.LOGOUT)
    await apiService.callAPI()
    logout()
    setLogoutLoading(false)
  }
  return (
    <>
      <div className='d-flex flex-column flex-row-fluid py-10'>
        <div className='px-md-15 px-8'>
          <div className='flex-end'>
            <Link to='/' className='mb-12 position-absolute top-0 start-0 mt-10 ms-10'>
              <img alt='Logo' src={BrandLogo} className='h-40px' />
            </Link>
          </div>
        </div>
        <div className='d-flex flex-center flex-column flex-column-fluid pt-lg-0 pt-15'>
          <div className='d-flex justify-content-center align-items-center bg-light-danger p-3 px-4 pills-radius'>
            <span className='bullet bullet-dot bg-danger me-2 h-10px w-10px'></span>
            <span className='fw-600 text-black fs-18'>{Dashboard.detailsRejected}</span>
          </div>
          <div className='text-center '>
            <div className='w-lg-650px py-7'>
              <h1 className='text-dark fs-36 fw-700'>
                {Dashboard.welcome}
                {status?.business.name}
              </h1>
              <p className='fs-20 fw-500 mb-0 pt-5'>
                {Dashboard.incorrectDocuments}
                {status?.business.KYC.statusMessage}
              </p>
            </div>
          </div>
          <div className='text-center'>
            <ContactUs
              handleClose={handleClose}
              show={show}
              handleShow={handleShow}
              sendFeedback={sendFeedback}
              handleMessage={(value: string) => {
                handleMessage(value)
              }}
              message={message}
              loading={loading}
            />
            <Button className='btn btn-lg btn-light-danger p-6 ms-4' onClick={handleLogout}>
              <span className='indicator-label fs-16'>Logout</span>
              {logoutLoading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <></>
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
// export {kycRejected}
export default KycRejected
