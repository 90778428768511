import { Modal, Button, Row, Col, FormLabel, Form, InputGroup } from 'react-bootstrap';
import CrossSvg from '../../_seller/assets/media/close.png'
import eyeIcon from '../../_seller/assets/media/auth/password.png'
import eyeActiveIcon from '../../_seller/assets/media/auth/showPassword.png'
import {useEffect, useState} from 'react'
import Validations from '../../utils/validations'
import clsx from 'clsx'
import {AUTH} from '../../api/apiEndPoints'
import APICallService from '../../api/apiCallService'
import {AuthJSON} from '../../api/apiJSON/auth'
import {error, success} from '../../Global/toast'
import {useAuth} from '../modules/auth'
const ChangePassword = (props: any) => {
  const [password, setPassword] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  })
  const {saveAuth} = useAuth()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  })
  const [validation, setValidation] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
    samePassword: false,
  })
  useEffect(()=>{
    setValidation({
      oldPassword: false,
      newPassword: false,
      confirmPassword: false,
      samePassword: false,
    })
  },[])
  const handleChange = (name: string, value: any) => {
    let temp: any = {...password}
    const tempValidation: any = {...validation}
    temp[name] = value
    tempValidation[name] = false
    if (!value.length) {
      tempValidation[name] = true
    }
    setPassword(temp)
    setValidation(tempValidation)
  }
  const handleShow = (name: string) => {
    let temp: any = {...showPassword}
    temp[name] = !temp[name]
    setShowPassword(temp)
  }
  const handleSubmit = async () => {
    const tempValidation = {...validation}
    const temp = {...password}
    if (temp.oldPassword == '') {
      tempValidation.oldPassword = true
    }
    if (temp.newPassword == '') {
      tempValidation.newPassword = true
    }
    if (temp.confirmPassword == '') {
      tempValidation.confirmPassword = true
    }
    if (temp.newPassword != '' && temp.confirmPassword != '') {
      if (temp.newPassword !== temp.confirmPassword) {
        error('New password and confirm password must be same!')
        tempValidation.confirmPassword = true
      }
    }
    const isValid = await Validations.validateObject(tempValidation)
    if (isValid) {
      setLoading(true)
      let apiService = new APICallService(AUTH.CHANGEPASSWORD, AuthJSON.changePassword(temp))
      let response = await apiService.callAPI()
      if (response) {
        saveAuth(response.token)
        success('Password changes successfully!')
        props.onHide()
      }
      setLoading(false)
    }
    setValidation(tempValidation)
  }
  return (
    <Modal
      {...props}
      show={props.show}
      onHide={() => {
        setValidation({
          oldPassword: false,
          newPassword: false,
          confirmPassword: false,
          samePassword: false,
        })
        props.onHide()
      }}
      dialogClassName='modal-dialog-centered min-w-588px'
      className='border-r10px'
      centered
      backdrop='static'
    >
      <Modal.Header className='border-bottom-0 pb-0 text-center mx-auto'>
        <img
          className='close-inner-top-2'
          width={40}
          height={40}
          src={CrossSvg}
          alt='closebutton'
          onClick={() => {
            setValidation({
              oldPassword: false,
              newPassword: false,
              confirmPassword: false,
              samePassword: false,
            })
            props.onHide()
          }}
        />
        <Modal.Title className='fs-26 fw-bolder mw-lg-375px pt-lg-3'>Change password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className='justify-content-center'>
          <Col lg={12}>
            <Row className='g-2'>
              <Col xs={12}>
                <FormLabel className='fs-16 fw-500 text-dark required'>Current password</FormLabel>
                <InputGroup
                  className={clsx(
                    'border border border-r8px',
                    validation.oldPassword ? 'border-danger border-1' : ''
                  )}
                >
                  <Form.Control
                    className='border-0 form-control-custom'
                    placeholder='Type here…'
                    name='oldPassword'
                    type={showPassword.oldPassword ? 'text' : 'password'}
                    onChange={(e: any) => {
                      handleChange(e.target.name, e.target.value.trimStart())
                    }}
                  />
                  <InputGroup.Text className='border-0 bg-white'>
                    <Button variant='link' className='btn-flush'>
                      <img
                        width={25}
                        height={16}
                        src={showPassword.oldPassword ? eyeActiveIcon : eyeIcon}
                        alt=''
                        onClick={() => {
                          handleShow('oldPassword')
                        }}
                      />
                    </Button>
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col xs={12}>
                <FormLabel className='fs-16 fw-500 text-dark required'>New password</FormLabel>
                <InputGroup
                  className={clsx(
                    'border border border-r8px',
                    validation.newPassword ? 'border-danger border-1' : ''
                  )}
                >
                  <Form.Control
                    className='border-0 form-control-custom'
                    placeholder='Type here…'
                    name='newPassword'
                    type={showPassword.newPassword ? 'text' : 'password'}
                    onChange={(e: any) => {
                      handleChange(e.target.name, e.target.value.trimStart())
                    }}
                  />
                  <InputGroup.Text className='border-0  bg-white'>
                    <Button variant='link' className='btn-flush'>
                      <img
                        width={25}
                        height={16}
                        src={showPassword.newPassword ? eyeActiveIcon : eyeIcon}
                        alt=''
                        onClick={() => {
                          handleShow('newPassword')
                        }}
                      />
                    </Button>
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col xs={12}>
                <FormLabel className='fs-16 fw-500 text-dark required'>
                  Confirm new password
                </FormLabel>
                <InputGroup
                  className={clsx(
                    'border border border-r8px',
                    validation.confirmPassword ? 'border-danger border-1' : ''
                  )}
                >
                  <Form.Control
                    className='border-0 form-control-custom'
                    placeholder='Type here…'
                    name='confirmPassword'
                    onChange={(e: any) => {
                      handleChange(e.target.name, e.target.value.trimStart())
                    }}
                    type={showPassword.confirmPassword ? 'text' : 'password'}
                  />
                  <InputGroup.Text className='border-0  bg-white'>
                    <Button variant='link' className='btn-flush'>
                      <img
                        width={25}
                        height={16}
                        src={showPassword.confirmPassword ? eyeActiveIcon : eyeIcon}
                        alt=''
                        onClick={() => {
                          handleShow('confirmPassword')
                        }}
                      />
                    </Button>
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className='justify-content-center mb-4 border-top-0'>
        <Button
          variant='primary'
          size='lg'
          onClick={() => {
            handleSubmit()
          }}
        >
          {!loading && <span className='indicator-label fs-16 fw-bold'>Save changes</span>}
          {loading && (
            <span className='indicator-progress fs-16 fw-bold' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default ChangePassword
