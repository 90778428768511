/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import APICallService from '../../../../api/apiCallService'
import { NOTIFICATION } from '../../../../api/apiEndPoints'
import { defaultJson } from '../../../../api/apiJSON/default'
import { useAuth } from '../../../../app/modules/auth'
import { INotification } from '../../../../types/responseIndex'
import { PAGE_LIMIT } from '../../../../utils/constants'
import Skeleton, { SkeletonTheme } from '../../../../app/custom/skeleton/index'
import '../../../../app/custom/skeleton/skeleton.css'
import Method from '../../../../utils/methods'
const HeaderNotificationsMenu = (props: any) => {
  const [notification, setNotification] = useState<INotification | undefined>(undefined)
  const [page, setPage] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAuth()

  useEffect(() => {
    ; (async () => {
      if (props.show) {
        setLoading(true)
        await fetchNotification(page)
        setLoading(false)
      }
    })()
  }, [props.show])
  
  const handleScroll = async (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      if (!(notification && notification.length === totalRecords)) {
        let tempPage = page
        tempPage = tempPage + 1
        setPage(tempPage)
        await fetchNotification(tempPage)
      }
    }
  }
  const fetchNotification = async (pageNo: number) => {
    let params = {
      pageNo: pageNo,
      limit: PAGE_LIMIT,
      sortKey: '_createdAt',
      sortOrder: -1,
    }
    let apiService = new APICallService(
      NOTIFICATION.GETNOTFICATION,
      defaultJson.listGeneral(params),
      {
        id: currentUser?.business.profile,
      }
    )
    let response = await apiService.callAPI()
    if (response) {
      if (pageNo === 1) {
        setTotalRecords(response.total)
      } else {
        let prevTotal = totalRecords
        setTotalRecords(prevTotal)
      }
      let data: any = notification ? [...notification] : []
      response.records.map((val: any) => {
        data.push(val)
      })
      setNotification(data)
    }
  }
  return (
    <div
      className={clsx(
        'menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px',
        props.show ? 'show' : ''
      )}
      popper-placement='bottom-end'
      data-kt-menu='true'
      style={{
        zIndex: 105,
        position: 'fixed',
        inset: '0px 0px auto auto',
        margin: '0px',
        transform: 'translate(-138px, 89px)',
      }}
    >
      <div className='d-flex flex-column rounded-top'>
        <h3 className='text-dark fw-bold px-6 my-6'>Notifications</h3>
      </div>
      <div className='border-bottom'></div>

      <div className='scroll-y mh-375px ' onScroll={handleScroll}>
        {loading ? (
          [1, 2, 3, 4, 5].map((val: any, index: number) => {
            return (
              <>
                <div className='px-3 my-4'>
                  <div className='d-flex mt-3'>
                    <SkeletonTheme baseColor='#fafafa' highlightColor='#f7f7f7'>
                      <Skeleton count={1} width={'325px'} height={'100%'} inline />
                    </SkeletonTheme>
                  </div>
                  <div className='d-flex mt-1'>
                    <SkeletonTheme baseColor='#fafafa' highlightColor='#f7f7f7'>
                      <Skeleton count={1} width={'186px'} height={'100%'} inline />
                    </SkeletonTheme>
                  </div>
                </div>
                {index !== 4 ? <div className='border-bottom'></div> : <></>}
              </>
            )
          })
        ) : notification && notification.length ? (
          notification.map((notifVal: any, index: number) => (
            <>
              <div key={`alert${index}`} className='d-flex flex-stack px-3 my-4'>
                <div className='d-flex '>
                  <div className='symbol symbol-35px m-2'>
                    <span className={clsx('symbol-label', `bg-white`)}>
                      <img src={notifVal.image} alt='check green' height={40} width={40} />
                    </span>
                  </div>
                  <div className='mb-0 mx-2'>
                    <p className='fs-16 text-dark fw-500 mb-0'>{notifVal.message}</p>
                    <div className='text-gray fw-500 fs-14'>
                      {Method.convertNotificationDate(notifVal._createdAt)}
                    </div>
                  </div>
                </div>
              </div>
              <div className='border-bottom'></div>
            </>
          ))
        ) : (
          <div className='d-flex justify-content-center align-items-center mh-375px h-200px'>
            <div className='text-dark fw-500 fs-16'>No Data Available</div>
          </div>
        )}
      </div>
    </div>
  )
}
export { HeaderNotificationsMenu }
