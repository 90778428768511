import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import CrossSvg from '../../_seller/assets/media/close.png'
import {InfoRequired} from '../../utils/constants'
const EditKYCDetails = (props: any) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        // contentClassName="py-md-5"
        dialogClassName='modal-dialog-centered min-w-lg-590px'
        className='border-r10px'
      >
        <Modal.Header className='border-bottom-0 pb-6'>
          <img
            className='close-inner-top-2'
            width={40}
            height={40}
            src={CrossSvg}
            alt='closebutton'
            onClick={props.onHide}
          />
          <Modal.Title className='mx-auto fs-26 fw-700'>Edit KYC details</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0 pb-4'>
          <div className='text-center px-lg-5'>
            <p className='fs-18 fw-500'>
              Your business KYC details{' '}
              {props.dashboardData.business?.KYC.status === InfoRequired
                ? 'requires more information'
                : 'are under review'}
              .
            </p>
            <p className='fs-18 fw-500'>
              If you edit the details, the review process will restart.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className='justify-content-center pt-0 border-top-0'>
          <Button onClick={props.handleEdit} className='btn btn-primary p-5'>
            {props.skipLoading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <span className='indicator-label'>Yes, proceed</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
// export {kycRejected}
export default EditKYCDetails
