import {Route, Routes} from 'react-router-dom'
import {SignUp} from './components/SignUp'
import {ForgotPassword} from './components/ForgotPassword'
import {CreatePassword} from './components/create-password'
import VerifyPassword from './components/VerifyPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<SignUp />} />
      <Route index element={<Login />} />
    </Route>
    <Route path='forgot-password' element={<ForgotPassword />} />
    <Route path='create-password' element={<CreatePassword />} />
    <Route path='verify-password' element={<VerifyPassword />} />
  </Routes>
)

export {AuthPage}
