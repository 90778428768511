import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_seller/helpers'

const Error404: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>

      <div className='fw-semibold fs-6 text-gray-500 mb-7'>We can't find that page.</div>

      <div className='mb-3'>
        <img
          src={toAbsoluteUrl('/media/auth/404-error.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
    </>
  )
}

export {Error404}
