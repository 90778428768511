export const auth = {
  resendOTP: 'We’ve sent an OTP to register email and phone number',
  verifiedEmail: 'Email verified!',
  verifiedOTP: 'OTP verified!',
  forgotOTPResend: 'OTP sent on your provided email or phone number!',
  register: 'Registered successfully!',
  login: 'Logged In done successfully!',
  passwordReset: 'Password reset successfully',
}
export const kyc = {
  feedbackSent: 'Feedback sent successfully!',
  submitSuccess: 'KYC submitted successfully!',
}
export const profile = {
  updateProfile: 'Profile updated successfully!',
  reverify: 'We’ve sent an OTP to your updated email and phone number',
}
export const catalogue = {
  added: 'Product details added successfully!',
  draft: 'Product details saved as draft!',
  updated: 'Product details updated successfully!',
  variantUpdated: 'Variant details updated successfully!',
}
