/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
// import ApexCharts, { ApexOptions } from 'apexcharts';
import {getCSSVariableValue} from '../../../../_seller/assets/ts/_utils'
import {useThemeMode} from '../../../../_seller/partials'
import {Card, Row, Col} from 'react-bootstrap'
import {CustomSelect} from '../../../custom/Select/CustomSelect'
import CustomDatePicker from '../../../custom/DateRange/DatePicker'
import {useNavigate} from 'react-router-dom'
import Chart from 'react-apexcharts'
import APICallService from '../../../../api/apiCallService'
import {DEFAULT, dashboard} from '../../../../api/apiEndPoints'
import Method from '../../../../utils/methods'
import Loader from '../../../../Global/loader'
import {useAuth} from '../../../modules/auth'
import {error} from '../../../../Global/toast'
import green from '../../../../_seller/assets/media/svg_uTrade/green_dot.svg'
import blue from '../../../../_seller/assets/media/svg_uTrade/blue-dot.svg'
import moment from 'moment'
const OrdersReport = () => {
  const {currentUser} = useAuth()
  const baseColor = getCSSVariableValue('--bs-primary')
  const [startDate, setStartDate] = useState<any>(
    new Date(new Date().setDate(new Date().getDate() - 7))
  )
  const [endDate, setEndDate] = useState<any>(new Date())
  const [chartSeries, setChartSeries] = useState<any>([])
  const [chartData, setChartData] = useState<any>([])
  const [ordersData, setOrderData] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [districts, setDistricts] = useState([])
  const [selectedDistrict, setSelectedDistrict] = useState<any>()
  useEffect(() => {
    ;(async () => {
      await fetchReports(startDate, endDate, selectedDistrict)
      await fetchDistrict(1, 10)
    })()
  }, [])
  const fetchDistrict = async (pageNo: number, limit: number) => {
    let params = {}
    let apiService = new APICallService(DEFAULT.DISTRICTS, params)
    let response = await apiService.callAPI()
    let data: any = []
    response.records.map((val: any) => {
      data.push({
        value: val._id,
        label: val.name,
        title: val.name,
        _id: val._id,
        name: val.name,
      })
    })
    setDistricts(data)
  }
  const fetchReports = async (fromDate: any, toDate: any, district: any) => {
    setLoading(true)
    let params: any = {
      fromDate: fromDate,
      toDate: toDate,
      //   fromDate: '2023-10-01',
      //   toDate: '2023-10-30',
      // utcOffset: -330,
      utcOffset: new Date().getTimezoneOffset(),
      reportType: '0',
    }
    if (district && district !== undefined) {
      params = {...params, 'districts[]': district._id}
    }
    let apiService = new APICallService(dashboard.orderReport, params, {
      id: currentUser?.business.profile,
    })
    let response = await apiService.callAPI()
    if (response) {
      if (response.data && response.data.length) {
        response.data = Method.populateMissingDates(
          response.data,
          Method.convertDateToFormat(fromDate, 'DD'),
          Method.convertDateToFormat(toDate, 'DD')
        )
        const allDatesSet = new Set([...response.data.map((entry: any) => entry.date)])
        const allDates: any = Array.from(allDatesSet)
        setCategories(allDates)
        let temp = [
          {
            name: 'Orders',
            data: response.data.map((entry: any) => ({
              x: entry.totalOrders,
              y: entry.totalSales.toFixed(2),
            })),
          },
        ]
        setChartData(response.data)
        setChartSeries(temp)
        setOrderData(response)
      } else {
        setChartData([])
        setChartSeries([])
        setOrderData(null)
      }
    }
    setLoading(false)
  }
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate)
    setEndDate(endDate)
    const startMonth = moment(new Date(startDate)).format('MM')
    const endMonth = moment(new Date(endDate)).format('MM')
    if (startDate && endDate) {
      if (startMonth === endMonth) {
        if (Method.dayDifference(startDate, endDate) >= 6) {
          await fetchReports(
            Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
            Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
            selectedDistrict !== undefined ? selectedDistrict : undefined
          )
        } else {
          error('Please select a date range with a minimum one-week difference')
        }
      } else {
        error('Please select a date range within a single month')
      }
    }
  }
  const handleDistrictChange = async (event: any) => {
    if (event) {
      setSelectedDistrict(event)
      await fetchReports(startDate, endDate, event)
    } else {
      setSelectedDistrict(undefined)
      await fetchReports(startDate, endDate, undefined)
    }
  }
  return (
    <>
      <Card className='mb-7 bg-f9f9f9'>
        <Card.Header className='pt-7 border-bottom-0'>
          <div className='card-title'>
            <h2 className='fs-22 fw-bolder'>Orders report</h2>
          </div>
          <div className='card-toolbar'>
            <div className='min-w-md-193px'>
              <CustomSelect
                backgroundColor='#ffff'
                minHeight='50px'
                disabled={loading}
                default={selectedDistrict}
                options={districts}
                loadingMessage={'Fetching Data'}
                isClearable={true}
                onChange={(e: any) => handleDistrictChange(e)}
              />
            </div>
            <div className='ms-3'>
              <CustomDatePicker
                className='bg-white min-h-50px fs-16 fw-bold text-dark min-w-md-260px'
                onChange={handleChange}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat='dd/MM/yyyy'
                showFullMonthYearPicker={true}
                maxDate={new Date()}
                inputTextBG='bg-white'
              />
            </div>
          </div>
        </Card.Header>
        {loading ? (
          <>
            <Card.Body className='py-0'>
              <div className='d-flex flex-center min-h-140px'>
                <Loader loading={loading} />
              </div>
            </Card.Body>
          </>
        ) : (
          <>
            {chartData.length > 0 ? (
              <>
                <Card.Body>
                  <Row className='g-5'>
                    <Col xs='auto' xl={4}>
                      <div className='border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5'>
                        <span className='fs-22 fw-bold'>
                          {ordersData.totalOrders} {ordersData.totalOrders > 1 ? 'orders' : 'order'}
                        </span>
                        <div className='fs-16 fw-500 text-gray-400'>Total orders</div>
                      </div>
                    </Col>
                    <Col xs='auto' xl={4}>
                      <div className='border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5'>
                        <span className='fs-22 fw-bold'>
                          {' '}
                          {ordersData.totalCancelledOrders}{' '}
                          {ordersData.totalCancelledOrders > 1 ? 'orders' : 'order'}
                        </span>
                        <div className='fs-16 fw-500 text-gray-400'>Total cancelled orders</div>
                      </div>
                    </Col>
                    <Col xs='auto' xl={4}>
                      <div className='border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5'>
                        <span className='fs-22 fw-bold'>
                          {' '}
                          {ordersData.totalProductsSold}{' '}
                          {ordersData.totalProductsSold > 1 ? 'products' : 'products'}
                        </span>
                        <div className='fs-16 fw-500 text-gray-400'>Total products sold</div>
                      </div>
                    </Col>
                    {/* <Col
                      xs="auto"
                      xl={3}
                    >
                      <div className="border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                        <span className="fs-22 fw-bold">
                          {ordersData.totalProductsSold} plans
                        </span>
                        <div className="fs-16 fw-500 text-gray-400">
                          Delivery plans generated
                        </div>
                      </div>
                    </Col> */}
                    <Col xs={12}>
                      <Chart
                        options={{
                          chart: {
                            fontFamily: 'inherit',
                            type: 'area' as 'area',
                            height: 275,
                            toolbar: {
                              show: false,
                            },
                            dropShadow: {
                              enabled: false,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            curve: 'smooth',
                            show: true,
                            width: 3,
                            colors: [baseColor],
                          },
                          legend: {
                            show: false,
                          },
                          xaxis: {
                            type: 'datetime',
                            categories: categories,
                            axisBorder: {
                              show: false,
                            },
                            axisTicks: {
                              show: false,
                            },
                            labels: {
                              style: {
                                colors: '#1a1a1a',
                                fontSize: '12px',
                              },
                            },
                            tooltip: {
                              enabled: false,
                            },
                          },
                          // yaxis: {
                          //   labels: {
                          //     align: 'center',
                          //     style: {
                          //       colors: '#7c7c7c',
                          //       fontSize: '12px',
                          //     },
                          //   },
                          // },
                          yaxis: [
                            // {
                            //   seriesName: 'Total Orders',
                            //   opposite: true,
                            //   labels: {
                            //     show: false,
                            //     align: 'center',
                            //     style: {
                            //       colors: '#7c7c7c',
                            //       fontSize: '12px',
                            //     },
                            //   },
                            // },
                            {
                              seriesName: '',
                              labels: {
                                align: 'center',
                                style: {
                                  colors: '#7c7c7c',
                                  fontSize: '12px',
                                },
                                formatter: function (value) {
                                  return Method.getGeneralizedAmount(value) // Adjust locale as needed
                                },
                              },
                            },
                          ],
                          tooltip: {
                            enabled: true,
                            custom: function ({series, seriesIndex, dataPointIndex, w}) {
                              const monthData = chartData[dataPointIndex]
                              return `<div style='width:164px !important'>
                                  <div class="p-2 mb-0"><span class="fw-500 fs-16 text-dark ms-2 mb-2">Total Orders</span><div class="d-flex align-items-center ms-2 mb-0 mt-2"><span class=" fw-600 fs-16 text-dark">${Method.getGeneralizedAmount(
                                    monthData.totalOrders
                                  )} ${monthData.totalOrders > 1 ? 'order' : 'orders'}</span> </div>
                                    </div>
            <div class='separator my-2'></div>
            <div class="p-2 mb-0 ms-2 mb-2"><span class="fw-500 fs-16 text-dark">Total Sales</span><div class="d-flex align-items-center mb-0 mt-2"><span class=" fw-600 fs-16 text-dark">TSh ${Method.getGeneralizedAmount(
              monthData.totalSales
            )}</span> </div></div>
                             `
                            },
                          },
                          colors: [baseColor],
                          grid: {
                            borderColor: '#e0e0df',
                            strokeDashArray: 5,
                            yaxis: {
                              lines: {
                                show: true,
                              },
                            },
                          },
                          fill: {
                            type: 'solid',
                            opacity: 0.05,
                          },
                          markers: {
                            size: 0,
                            colors: undefined,
                            strokeColors: '#1b74e4',
                            strokeWidth: 8,
                            strokeOpacity: 1,
                            strokeDashArray: 0,
                            fillOpacity: 1,
                            shape: 'circle',
                            radius: 0,
                            onClick: undefined,
                            onDblClick: undefined,
                            showNullDataPoints: false,
                            hover: {
                              size: undefined,
                              sizeOffset: 3,
                            },
                          },
                        }}
                        series={[
                          {
                            name: '',
                            data: chartData.map((entry: any) => entry.totalSales.toFixed(2)),
                          },
                        ]}
                        type='area'
                        height='350'
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </>
            ) : (
              <>
                <Card.Body className='py-0'>
                  <div className='d-flex flex-center min-h-140px'>
                    <span className='fs-18 fw-medium'>No details available for these dates.</span>
                  </div>
                </Card.Body>
              </>
            )}
          </>
        )}
      </Card>
    </>
  )
}
export default OrdersReport
