import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import './dateRange.css';

const CustomDatePicker = (props: any) => {
  return (
    <>
      <DatePicker
        className={props.className + " form-control text-dark border border-r8px "}
        selected={props.selected}
        onChange={props.onChange}
        startDate={props.startDate}
        endDate={props.endDate}
        dateFormat={props.dateFormat}
        selectsRange={props.selectsRange}
        showFullMonthYearPicker={props.showFullMonthYearPicker}
        maxDate={props.maxDate}
        isClearable={props.isClearable}
      />
    </>
  );
};
export default CustomDatePicker;
