import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Cropper, CropperRef } from 'react-advanced-cropper'
import 'react-advanced-cropper/dist/style.css'

import CrossSvg from '../../_seller/assets/media/close.png'

const ImgCropModal = (props: any) => {
  const [image, setImage] = useState(props.image)
  const cropperRef = useRef<CropperRef>(null)
  const onCrop = async () => {
    const cropper = cropperRef.current
    if (cropper) {
      const canvas = cropper.getCanvas()
      if (canvas) {
        // let data: any = {}
        // await canvas.toBlob((blob) => {
        //   data = blob
        //   return blob
        // })
        props.onChange(canvas.toDataURL(), canvas.toDataURL())
      }
    }
  }
  const handleImageChange = (event: any) => {
    const selectedFiles = event.target.files

    if (!selectedFiles) return
    else {
      setImage(URL.createObjectURL(selectedFiles?.[0]))
    }
  }
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        contentClassName='py-md-5'
        dialogClassName='modal-dialog-centered min-w-lg-1000px min-w-md-750px'
        className='border-r10px'
      >
        <Modal.Header className='cropper-modal border-bottom-0 pt-lg-2 pt-md-1 pb-8'>
          <img
            className='close-inner-top cursor-pointer'
            width={40}
            height={40}
            src={CrossSvg}
            alt='closebutton'
            // onClick={handleClose}
            onClick={props.onHide}
          />
          <Modal.Title className='mx-auto fs-26 fw-700'>Crop business logo</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0 pb-4'>
          <div className='px-lg-5 img-crop-box d-flex justify-content-center h-auto'>
            <Cropper
              ref={cropperRef}
              className='example__cropper min-w-lg-375px min-h-375px mh-300px border-r10px'
              backgroundClassName='example__cropper-background'
              src={image}
              stencilProps={{
                aspectRatio: 1 / 1,
                // resizable: false,
              }}
            />
            {/* <div
              className='image-input-wrapper shadow-none bgi-contain bgi-no-repeat bgi-position-center min-w-lg-375px min-h-375px'
              style={{backgroundImage: `url(${StockImg})`}}
            ></div> */}

            {/* <ReactCrop
              // crop={crop}

              // onChange={(c) => setCrop(c)}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              crop={crop}
              minHeight={100}
              maxHeight={100}
              // maxHeight={250}
              // maxWidth={500}
              locked={true}
              // onInitialized={(instance: any) => {
              //   setCropper(instance)
              // }}
            >
              <img src={props.image} height={300} />
            </ReactCrop> */}
          </div>
        </Modal.Body>
        <Modal.Footer className='justify-content-center pt-4 border-top-0'>
          <div className='upload-btn-wrapper'>
            <input type='file' name='myfile' onChange={handleImageChange} />
            <Button variant='light-primary' className='p-5'>
              Change photo
            </Button>
          </div>
          <Button variant='primary' className='p-5' onClick={onCrop}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

// export {kycRejected}
export default ImgCropModal
