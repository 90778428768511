/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {success} from '../../../../Global/toast'
import Password from '../../../../_seller/assets/media/auth/password.png'
import ShowPassword from '../../../../_seller/assets/media/auth/showPassword.png'
import APICallService from '../../../../api/apiCallService'
import {AUTH} from '../../../../api/apiEndPoints'
import {AuthJSON} from '../../../../api/apiJSON/auth'
import {auth} from '../../../../utils/toast'
import {useAuth} from '../core/Auth'
const loginSchema = Yup.object().shape({
  email: Yup.string().required('Email / Phone is required'),
  // .email('Wrong email format')
  // .min(3, 'Minimum 3 symbols')
  // .max(50, 'Maximum 50 symbols')
  // .required('Email is required'),
  password: Yup.string()
    .min(6, 'Length should be minimum 6')
    .max(15, 'Length should be maximum 15')
    .required('Password is required'),
})
const validateEmail = (email: string | undefined) => {
  return Yup.string().email().isValidSync(email)
}
const validatePhone = (phone: number | undefined) => {
  return Yup.number()
    .integer()
    .positive()
    .test((phone) => {
      return phone && phone.toString().length >= 8 && phone.toString().length <= 14 ? true : false
    })
    .isValidSync(phone)
}
const initialValues = {
  email: '',
  password: '',
}
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, saveCurrentUser} = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        if (/^\d+$/.test(values.email)) {
          let apiService = new APICallService(
            AUTH.LOGIN,
            AuthJSON.login({
              phone: values.email,
              phoneCountry: '+255',
              password: values.password,
            })
          )
          let response = await apiService.callAPI()
          if (response) {
            saveAuth(response.token)
            let user = response.user
            saveCurrentUser(user)
            success(auth.login)
            // let params = {
            //   needCount: false,
            //   categoriesDepth: 3,
            // }
            // let categoryApiService = new APICallService(
            //   DEFAULT.CATEGORIES,
            //   defaultJson.listCategory(params)
            // )
            // let categoryResponse = await categoryApiService.callAPI()
            // if (categoryResponse) {
            //   secureLocalStorage.setItem('categories', JSON.stringify(categoryResponse.records))
            // }
            // let businessApiService = new APICallService(DEFAULT.BUSINESSTYPES, params)
            // let businessresponse = await businessApiService.callAPI()
            // if (businessresponse) {
            //   secureLocalStorage.setItem('businessTypes', JSON.stringify(businessresponse.records))
            // }
            // let certificatesApiService = new APICallService(DEFAULT.CERTIFICATES)
            // let certificateResponse = await certificatesApiService.callAPI()
            // if (certificateResponse) {
            //   secureLocalStorage.setItem(
            //     'businessCertificates',
            //     JSON.stringify(certificateResponse.records)
            //   )
            // }
          }
          setLoading(false)
        } else {
          let apiService = new APICallService(
            AUTH.LOGIN,
            AuthJSON.login({
              password: values.password,
              email: values.email,
            })
          )
          let response = await apiService.callAPI()
          if (response) {
            saveAuth(response.token)
            let user = response.user
            saveCurrentUser(user)
            success(auth.login)
            // let params = {
            //   needCount: false,
            //   categoriesDepth: 3,
            // }
            // let categoryApiService = new APICallService(
            //   DEFAULT.CATEGORIES,
            //   defaultJson.listCategory(params)
            // )
            // let categoryResponse = await categoryApiService.callAPI()
            // if (categoryResponse) {
            //   secureLocalStorage.setItem('categories', JSON.stringify(categoryResponse.records))
            // }
            // let businessApiService = new APICallService(DEFAULT.BUSINESSTYPES, params)
            // let businessresponse = await businessApiService.callAPI()
            // if (businessresponse) {
            //   secureLocalStorage.setItem('businessTypes', JSON.stringify(businessresponse.records))
            // }
            // let certificatesApiService = new APICallService(DEFAULT.CERTIFICATES)
            // let certificateResponse = await certificatesApiService.callAPI()
            // if (certificateResponse) {
            //   secureLocalStorage.setItem(
            //     'businessCertificates',
            //     JSON.stringify(certificateResponse.records)
            //   )
            // }
          }
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
      }
      setSubmitting(false)
      setLoading(false)
    },
  })
  return (
    <form
      className='form w-100 position-relative'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-left mb-6'>
        <h1 className='text-dark fs-35 fw-bolder mb-3'>
          Sign in to your seller <br className='br' />
          Business account!
        </h1>
      </div>
      <div className='fv-row mb-3'>
        <input
          placeholder='Email or mobile phone number'
          {...formik.getFieldProps('email')}
          name='email'
          value={formik.values.email.trimStart()}
          onChange={(e) => {
            formik.handleChange(e)
          }}
          className={clsx('form-control form-control-custom')}
          type='text'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert' className='text-danger fs-14 fw-500'>
              {formik.errors.email}
            </span>
          </div>
        )}
      </div>
      <div className='fv-row mb-6'>
        <div className='input-group input-group-solid h-60px border-0 bg-light'>
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            value={formik.values.password.trimStart()}
            onChange={(e) => {
              formik.handleChange(e)
            }}
            className={clsx(
              'form-control form-control-custom',
              {
                ' border-left-0': formik.touched.password && formik.errors.password,
              },
              {
                ' border-0': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <span
            className={clsx(
              'input-group-text fs-16 fw-500 text-dark bg-light',
              {
                ' border-left-0': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid border-0': formik.touched.password && !formik.errors.password,
              }
            )}
            id='basic-addon1'
          >
            {showPassword ? (
              <img
                height={15}
                width={25}
                src={ShowPassword}
                onClick={() => {
                  setShowPassword(!showPassword)
                }}
                alt=''
              ></img>
            ) : (
              <img
                height={15}
                width={25}
                src={Password}
                onClick={() => {
                  formik.touched.password ? setShowPassword(!showPassword) : setShowPassword(false)
                }}
                alt=''
              ></img>
            )}
          </span>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger fs-14 fw-500'>
                {formik.errors.password}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='d-grid'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary btn-lg'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign in</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <div className='w-100 text-center mb-10 mb-lg-20 mt-5'>
        <Link to='/auth/forgot-password' className='link-dark fs-16 fw-normal'>
          Forgot Password?
        </Link>
      </div>
      <div className='auth-footer w-100 text-center'>
        <span className='fs-16 fw-normal'>
          Don’t have an account?
          <Link to='/auth/registration' className='link-primary fw-bold ms-1'>
            Sign up
          </Link>
        </span>
      </div>
    </form>
  )
}
