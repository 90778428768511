/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import clsx from 'clsx';
import { useLayout } from '../../core';
import { MenuItem } from '../header/header-menus/MenuItem';
import { MenuInnerWithSub } from '../header/header-menus/MenuInnerWithSub';
import { useIntl } from 'react-intl';

const SidebarDefault: FC = () => {
    const { config, classes } = useLayout();
    const intl = useIntl()
    return (
        <div
            id="kt_app_sidebar"
            className={clsx('app-sidebar', classes.sidebar.join(' '), {
                'd-none': !config.app?.sidebar?.display,
            })}
            data-kt-drawer="true"
            data-kt-drawer-name="app-sidebar"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'200px', '300px': '225px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
        >
            <div
                className="hover-scroll-overlay-y my-5 my-lg-5 w-100 ps-4 ps-lg-0 pe-4 me-1"
                id="kt_app_sidebar_menu_wrapper"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-height="auto"
                data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
                data-kt-scroll-wrappers="#kt_app_sidebar_menu"
                data-kt-scroll-offset="5px"
            >
                <div
                    className="menu menu-column menu-active-bg menu-hover-bg menu-title-gray-700 fs-6 menu-rounded w-100"
                    id="#kt_app_sidebar_menu"
                    data-kt-menu="true"
                >
                    <MenuItem title={intl.formatMessage({ id: 'Menu.Dashboard' })} to='/dashboard' />
                    <MenuInnerWithSub
                        title={intl.formatMessage({ id: 'Menu.Catalogue' })}
                        to='/builder'
                        menuPlacement='bottom-start'
                        hasArrow={true}
                        menuTrigger='click'
                    >
                        <MenuItem to='/catalogue/add-new-product' title='Add new product' hasBullet={false} />
                        <div className='separator'></div>
                        <MenuItem to='/catalogue/manage-products' title='Manage products' hasBullet={false} />
                    </MenuInnerWithSub>
                    <MenuInnerWithSub
                        title={intl.formatMessage({ id: 'Menu.Inventory' })}
                        to='/inventory/'
                        menuPlacement='bottom-start'
                        hasArrow={true}
                        menuTrigger='click'
                    >
                        <MenuItem to='/inventory/my-inventory' title='My Inventory' hasBullet={false} />
                        <div className='separator'></div>
                        <MenuItem to='/inventory/goods-requests' title='Goods requests' hasBullet={false} />
                        {/* <MenuItem to='/inventory/return-goods' title='Return goods' hasBullet={true} /> */}
                    </MenuInnerWithSub>
                    <MenuItem title={intl.formatMessage({ id: 'Menu.Orders' })} to='/orders' />
                    <MenuItem title={'Advertising'} to='/advertising/advertisement' />
                </div>
            </div>
        </div>
    );
};

export { SidebarDefault };
