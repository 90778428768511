import clsx from 'clsx'
import {Col, Row} from 'react-bootstrap'
import AutoComplete from '../../../../custom/autoComplete'
const Step1 = (props: any) => {
  return (
    <div className='kyc-process'>
      <div className='mb-9 text-center'>
        <h2 className='d-flex justify-content-center fs-34 fw-bolder text-dark mb-3'>
          Please add basic details of your business
        </h2>
        <div className='fs-18 fw-500 text-gray'>
          Please fill all the details as per the business registration / license.
        </div>
      </div>
      <Row className='border border-r10px p-xxl-20 p-lg-9 p-6 text-start bg-light'>
        <Col lg={6} className='mb-5'>
          <div className='row align-items-center'>
            <div className='col-lg-4'>
              <label htmlFor=''>Name</label>
            </div>
            <div className='col-lg'>
              <input
                className={clsx('form-control form-control-custom bg-white')}
                placeholder='Type here…'
                type='text'
                name='ownerName'
                value={props.basicDetails.ownerName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  props.onChange(e.target.name, e.target.value)
                }
              />
            </div>
          </div>
        </Col>
       
        <Col lg={6} className='mb-5'>
          <div className='row align-items-center'>
            <div className='col-lg-4'>
              <label htmlFor=''>Business Name</label>
            </div>
            <div className='col-lg'>
              <input
                className={clsx('form-control form-control-custom bg-white')}
                placeholder='Type here…'
                name='name'
                type='text'
                value={props.basicDetails.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  props.onChange(e.target.name, e.target.value)
                }
              />
            </div>
          </div>
        </Col>
        <Col lg={12} className='mb-5'>
          <div className='row align-items-center'>
            <div className='col-lg-2'>
              <label htmlFor=''>Business address</label>
            </div>
            <div className='col-lg'>
              {/* <input
                className={clsx('form-control form-control-custom bg-white')}
                placeholder='Type here…'
                type='text'
                name='address'
                value={props.basicDetails.address}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  props.onChange(e.target.name, e.target.value)
                }
              /> */}
              <AutoComplete
                address={props.basicDetails.address}
                handleAddressChange={props.handleAddressChange}
                lat={props.basicDetails.lat}
                lng={props.basicDetails.lng}
              />
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className='row'>
            <div className='col-lg-2'>
              <label htmlFor=''>About the business</label>
            </div>
            <div className='col-lg'>
              <textarea
                className={clsx('form-control form-control-custom bg-white')}
                name='about'
                id=''
                placeholder='Type here…'
                rows={5}
                value={props.basicDetails.about}
                onChange={(e): void => props.onChange(e.target.name, e.target.value)}
              />
            </div>
          </div>
        </Col>
        {/* content-end-here */}
      </Row>
    </div>
  )
}
export {Step1}
