/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import BrandLogo from '../../../_seller/assets/media/uTradeLogo.png'
import {useAuth} from './core/Auth'
import APICallService from '../../../api/apiCallService'
import {AUTH, KYC} from '../../../api/apiEndPoints'
import {KycJson} from '../../../api/apiJSON/kyc'
import {toast} from 'react-toastify'
import {kyc} from '../../../utils/toast'
import {Button, Col, Row} from 'react-bootstrap'
import ContactUs from '../../modals/ContactUs'

const EmptyData = () => {
  const navigate = useNavigate()
  const {logout, currentUser, saveCurrentUser} = useAuth()
  const [message, setMessage] = useState('')
  const [contactShow, setContactShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleClose = () => setContactShow(false)
  const handleShow = () => setContactShow(true)
  const sendFeedback = async () => {
    setLoading(true)
    let apiService = new APICallService(KYC.KYCFEEDBACK, KycJson.kycFeedback({message}), {
      id: currentUser?.business.profile,
    })
    let response = await apiService.callAPI()
    if (response) {
      toast.success(kyc.feedbackSent, {autoClose: 1000, theme: 'colored'})
    }
    setMessage('')
    setContactShow(false)
    setLoading(false)
  }

  const handleMessage = (value: string) => {
    setMessage(value)
  }
  const handleLogout = async () => {
    let apiService = new APICallService(AUTH.LOGOUT)
    await apiService.callAPI()
    logout()
  }
  return (
    <div className='d-flex flex-column flex-row-fluid py-10'>
      <Row className='align-items-center position-absolute top-0 start-0 w-100 p-5 pe-0'>
        <Col xs>
          <Link to='/'>
            <img alt='Logo' src={BrandLogo} className='h-40px' />
          </Link>
        </Col>
        <Col xs='auto'>
          <Button
            variant='danger'
            size='lg'
            className='btn-active-danger mh-50px'
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Col>
      </Row>

      <div className='d-flex justify-content-center flex-center flex-column flex-column-fluid'>
        <div className='d-flex  pt-lg-0 pt-15 w-600px'>
          <div className='text-center'>
            <div className='py-6 pb-4'>
              <p className='text-dark fs-18 fw-600'>Dear {currentUser?.name} 👋 ,</p>
              <p className='text-dark fs-18 fw-600'>
                We would like to inform you that once our admin adds the necessary data, you will be
                able to proceed with the KYC (Know Your Customer) process. We understand the
                importance of verifying your identity, and we are working diligently to ensure a
                smooth and secure experience for you.
              </p>
              <p className='text-dark fs-18 fw-600'>
                We appreciate your patience in this matter and apologize for any inconvenience
                caused. Rest assured, as soon as the required information is added, you will be
                notified and can proceed with the KYC process to enjoy the full benefits of our
                services.
              </p>
              <p className='text-dark fs-18 fw-600'>
                Thank you for choosing our platform, and we look forward to serving you soon.
              </p>
            </div>

            <div className='text-center'>
              <ContactUs
                handleClose={handleClose}
                show={contactShow}
                handleShow={handleShow}
                sendFeedback={sendFeedback}
                handleMessage={(value: string) => {
                  handleMessage(value)
                }}
                message={message}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {EmptyData}
