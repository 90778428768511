/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_seller/helpers'

// Media
import PlayBtn from '../../../_seller/assets/media/svg_uTrade/play-icon.svg'
import BrandLogo from '../../../_seller/assets/media/uTradeLogo.png'
import {useAuth} from './core/Auth'
import APICallService from '../../../api/apiCallService'
import {KYC} from '../../../api/apiEndPoints'
import {KycJson} from '../../../api/apiJSON/kyc'

const WelcomePage = () => {
  const navigate = useNavigate()
  const {currentUser, saveCurrentUser} = useAuth()
  const [kycData, setKycData] = useState<{
    name: string
    ownerName: string
    address: string
    about: string
    categories: any
    image: string
    imageReader: any
    businessTypes: any
    businessCertificates: any
    currentStep: number
    submitForReview: boolean
  }>({
    name: '',
    ownerName: '',
    address: '',
    about: '',
    categories: [],
    image: '',
    imageReader: {},
    businessTypes: [],
    businessCertificates: [],
    currentStep: 0,
    submitForReview: false,
  })
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  const handleSkip = async () => {
    kycData.ownerName = currentUser ? currentUser?.name : ''
    kycData.name = currentUser?.business.name

    let apiService = new APICallService(KYC.KYCUPDATE, KycJson.kycUpdate({kycData}), {
      id: currentUser?.business.profile,
    })
    let response = await apiService.callAPI()
    if (response) {
      let user: any = currentUser
      user.business.KYC.initiatedEver = true
      saveCurrentUser(user)
      navigate('/dashboard')
    }
  }

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div className='d-flex flex-lg-row-fluid position-relative'>
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          <div className='mb-lg-0 mb-md-5 mb-10'>
            <Link to='/' className='mb-12 position-absolute top-0 start-0 mt-10 ms-10 ps-lg-10'>
              <img alt='Logo' src={BrandLogo} className='h-40px' />
            </Link>
          </div>

          <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
            <div className='mx-auto w-275px w-md-75 w-xl-500px my-lg-0 my-10 ps-lg-10'>
              <div className='mb-9'>
                <h1 className='fs-34 fw-bold'>
                  Welcome <br className='br' />
                  {currentUser?.name} 👋 <br className='br' />
                </h1>
                <p className='fs-20 fw-500 pt-6'>
                  We require some details to verify your <br className='br-md' />
                  business. Please proceed to provide info <br className='br-md' /> about your
                  business and documents.
                </p>
              </div>

              <Link to='/kyc-process' className='btn btn-primary btn-lg'>
                Complete KYC
              </Link>

              <div className='fs-16 fw-600'>
                <span
                  // to='/dashboard'
                  className='text-gray mb-10 position-absolute bottom-0 start-0 mt-10 ms-10 ps-lg-10'
                  style={{cursor: 'pointer'}}
                  onClick={handleSkip}
                >
                  I’ll complete my business KYC later.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-lg-row-fluid min-w-lg-800px order-lg-first position-relative'>
        <div
          className='overlay-wrapper flex-grow-1 bgi-no-repeat bgi-size-cover bgi-position-center'
          style={{
            height: '100vh',
            backgroundImage: `url('${toAbsoluteUrl('/media/avatars/300-16.jpg')}')`,
          }}
        >
          <a href='' className='play-btn'>
            <img className='img-fluid' src={PlayBtn} width='100' height='100' alt='' />
          </a>
        </div>
      </div>
    </div>
  )
}

export {WelcomePage}
