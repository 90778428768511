import { error } from './toast';

export const fileValidation = (files: any) => {
  const MIN_FILE_SIZE = 1024 // 1MB
  const MAX_FILE_SIZE = 5120 // 5MB
  let successReturn = true
  const fileSizeKiloBytes = files.size / 1024

  if (fileSizeKiloBytes > MAX_FILE_SIZE) {
    error('File size is greater than maximum limit')
    successReturn = false

    return false
  }
  if (!files.name.match(/\.(jpg|jpeg|png|)$/)) {
    error('Please select valid image.')
    return false
  }
  return true
}
export const fileValidationSVG = (files: any) => {
  const MIN_FILE_SIZE = 1024 // 1MB
  const MAX_FILE_SIZE = 5120 // 5MB
  let successReturn = true
  const fileSizeKiloBytes = files.size / 1024

  if (fileSizeKiloBytes > MAX_FILE_SIZE) {
    error('File size is greater than maximum limit')
    successReturn = false

    return false
  }
  if (!files.name.match(/\.(svg|)$/)) {
    error('Please upload svg image.')
    return false
  }
  return true
}
