/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Link, Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_seller/helpers'
// Media'
import BrandLogo from '../../../_seller/assets/media/uTradeLogo.png'
const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])
  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 bg-body p-10 order-2 overflow-lg-scroll'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
        </div>
      </div>
      <div className='d-flex flex-lg-row-fluid w-lg-50 bg-light-primary order-1 position-relative'>
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          <div className='mb-lg-0 mb-md-5 mb-10'>
            <Link to='/' className='mb-12 position-absolute top-0 start-0 mt-10 ms-10'>
              <img alt='Logo' src={BrandLogo} className='h-40px' />
            </Link>
          </div>
          <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
            <div className='mx-auto w-275px w-md-75 w-xl-500px'>
              <div
                id='kt_carousel_1_carousel'
                className='carousel carousel-custom slide'
                data-bs-ride='carousel'
                data-bs-interval='8000'
              >
                <div className='carousel-inner text-center'>
                  <div className='carousel-item active fs-22 fw-600'>
                    <div className='d-flex flex-center symbol symbol-lg-150px symbol-md-125px symbol-100px symbol-circle mb-7'>
                      <div
                        className='symbol-label'
                        style={{
                          backgroundImage: `url('${toAbsoluteUrl('/media/avatars/300-14.jpg')}')`,
                        }}
                      ></div>
                    </div>
                    “It is easy to work with software that has combined our operational functions
                    into one platform. It’s user-friendly and adjusted to the business needs.”
                    <div className='pt-3 fs-18 fw-600 text-italic'>
                      <span className='fw-bolder'>Dan Wood</span> - Founder, Acme Corporation
                    </div>
                  </div>
                  <div className='carousel-item fs-22 fw-600'>
                    <div className='d-flex flex-center symbol symbol-lg-150px symbol-md-125px symbol-100px symbol-circle mb-7'>
                      <div
                        className='symbol-label'
                        style={{
                          backgroundImage: `url('${toAbsoluteUrl('/media/avatars/300-8.jpg')}')`,
                        }}
                      ></div>
                    </div>
                    “Since the uTrade has been introduced to me, it save 25-30% of our time looking
                    for the best technology products at the best prices”
                    <div className='pt-3 fs-18 fw-600 text-italic'>
                      <span className='fw-bolder'>Doris Schneider</span> - Co-Founder, Mhina
                      Stepping Stones
                    </div>
                  </div>
                  <div className='carousel-item fs-22 fw-600'>
                    <div className='d-flex flex-center symbol symbol-lg-150px symbol-md-125px symbol-100px symbol-circle mb-7'>
                      <div
                        className='symbol-label'
                        style={{
                          backgroundImage: `url('${toAbsoluteUrl('/media/avatars/300-16.jpg')}')`,
                        }}
                      ></div>
                    </div>
                    “It is easy to work with software that has combined our operational functions
                    into one platform. It’s user-friendly and adjusted to the business needs.”
                    <div className='pt-3 fs-18 fw-600 text-italic'>
                      <span className='fw-bolder'>Dan Wood</span> - Founder, Acme Corporation
                    </div>
                  </div>
                </div>
                <div className='auth-footer-2 mt-lg-0 mt-10'>
                  <ol className='p-0 m-0 carousel-indicators carousel-indicators-dots'>
                    <li
                      data-bs-target='#kt_carousel_1_carousel'
                      data-bs-slide-to='0'
                      className='ms-1 active'
                    ></li>
                    <li
                      data-bs-target='#kt_carousel_1_carousel'
                      data-bs-slide-to='1'
                      className='ms-1'
                    ></li>
                    <li
                      data-bs-target='#kt_carousel_1_carousel'
                      data-bs-slide-to='2'
                      className='ms-1'
                    ></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export {AuthLayout}
