import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'Menu.Dashboard'})} to='/dashboard' />
      <MenuInnerWithSub
        title={intl.formatMessage({id: 'Menu.Catalogue'})}
        to='/builder'
        menuPlacement='bottom-start'
        hasArrow={true}
        menuTrigger='click'
      >
        <MenuItem to='/catalogue/add-new-product' title='Add new product' hasBullet={false} />
        <div className='separator'></div>
        <MenuItem to='/catalogue/manage-products' title='Manage products' hasBullet={false} />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({id: 'Menu.Inventory'})}
        to='/inventory/'
        menuPlacement='bottom-start'
        hasArrow={true}
        menuTrigger='click'
      >
        <MenuItem to='/inventory/my-inventory' title='My Inventory' hasBullet={false} />
        <div className='separator'></div>
        <MenuItem to='/inventory/goods-requests' title='Goods requests' hasBullet={false} />
        {/* <MenuItem to='/inventory/return-goods' title='Return goods' hasBullet={true} /> */}
      </MenuInnerWithSub>
      <MenuItem title={intl.formatMessage({id: 'Menu.Orders'})} to='/orders' />
      <MenuItem title={'Advertising'} to='/advertising/advertisement' />
    </>
  )
}
