/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {Rejected} from '../../utils/constants'
import KycRejected from '../modules/auth/kyc-process/details/kycRejected'
import VerifyOTP from '../modules/auth/components/VerifyOTP'
import {WelcomePage} from '../modules/auth/WelcomePage'
import {KycStepper} from '../modules/auth/kyc-process/KycStepper'
import KycSubmitted from '../modules/auth/kyc-process/details/kycSubmitted'
import PrivacyPolicy from '../modules/auth/components/privacy-policy'
import TermsAndConditions from '../modules/auth/components/terms-and-conditions'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
const AppRoutes: FC = (props) => {
  const {currentUser} = useAuth()
  // console.log = () => {}
  // console.error = () => {}
  // console.warn = () => {}
  // console.trace = () => {}
  // console.debug = () => {}
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <>
                {currentUser.emailVerified ? (
                  currentUser.business.KYC.submittedEver ? (
                    currentUser.business.KYC.status === Rejected ? (
                      <>
                        <Route index element={<Navigate to='/kyc-rejected' />} />
                        <Route path='kyc-rejected' element={<KycRejected />} />
                        <Route path='*' element={<Navigate to='/kyc-rejected' />} />
                      </>
                    ) : (
                      <>
                        <Route index element={<Navigate to='/dashboard' />} />
                        <Route path='/*' element={<PrivateRoutes />} />
                      </>
                    )
                  ) : currentUser.business.KYC.initiatedEver ? (
                    <>
                      <Route index element={<Navigate to='/dashboard' />} />
                      <Route path='kyc-submitted' element={<KycSubmitted />} />
                      <Route path='/verify-otp' element={<Navigate to='/kyc-process' />} />
                      <Route path='/*' element={<PrivateRoutes />} />
                    </>
                  ) : currentUser.business.KYC.initiatedEver ? (
                    <>
                      <Route index element={<Navigate to='/dashboard' />} />
                      <Route path='/*' element={<PrivateRoutes />} />
                    </>
                  ) : (
                    <>
                      <Route index element={<Navigate to='/welcome' />} />
                      <Route path='/welcome' element={<WelcomePage />} />
                      <Route path='verify-otp' element={<Navigate to='/welcome' />} />
                      <Route path='welcome' element={<Navigate to='/kyc-process' />} />
                      <Route path='kyc-process' element={<KycStepper />} />
                    </>
                  )
                ) : (
                  <>
                    <Route index element={<Navigate to='/verify-otp' />} />
                    <Route path='verify-otp' element={<VerifyOTP />} />
                    <Route path='*' element={<Navigate to='/verify-otp' />} />
                  </>
                )}
                <Route path='/*' element={<PrivateRoutes />} />
              </>
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
export {AppRoutes}
