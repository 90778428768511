/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import APICallService from '../../../../api/apiCallService'
import {AUTH} from '../../../../api/apiEndPoints'
import BankDetails from '../../../../app/modals/bank-details'
import ChangePassword from '../../../../app/modals/change-password'
const HeaderUserMenu: FC = () => {
  const {logout, currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [bankDetails, setBankDetails] = useState(false)
  const [password, setPassword] = useState(false)
  const handleLogout = async () => {
    setLoading(true)
    let apiService = new APICallService(AUTH.LOGOUT)
    await apiService.callAPI()
    logout()
    setLoading(false)
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-900 menu-state-bg menu-state-primary fw-500  fs-16 w-160px py-2'
      data-kt-menu='true'
    >
      <BankDetails show={bankDetails} onHide={() => setBankDetails(false)} />
      <ChangePassword show={password} onHide={() => setPassword(false)} />
      {currentUser?.business.KYC.submittedEver ? (
        <>
          <div className='menu-item px-3'>
            <Link to='profile-management/manage-profile' className='menu-link px-3'>
              Manage profile
            </Link>
          </div>
          <div className='separator'></div>
        </>
      ) : (
        <></>
      )}
      <div className='menu-item px-3'>
        <a
          onClick={() => {
            setBankDetails(true)
          }}
          className='menu-link px-3'
        >
          {loading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            'Edit bank details'
          )}
        </a>
      </div>
      <div className='separator'></div>
      <div className='menu-item px-3'>
        <a
          onClick={() => {
            setPassword(true)
          }}
          className='menu-link px-3'
        >
          {loading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            'Change password'
          )}
        </a>
      </div>
      <div className='separator'></div>
      <div className='menu-item px-3'>
        <a onClick={handleLogout} className='menu-link px-3'>
          {loading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            'Logout'
          )}
        </a>
      </div>
    </div>
  )
}
export {HeaderUserMenu}
