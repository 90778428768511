import {IProfileUpdate, IUpdateBankDetails, IVerifyProfile} from '../../types'
export const ProfileJson = {
  UpdateProfile: ({business, pendingCertificates, code}: IProfileUpdate) => {
    let form = new FormData()
    if (business.ownerName !== '') form.append('ownerName', business.ownerName)
    if (business.name !== '') form.append('name', business.name)
    if (code && code !== '') {
      form.append('code', code)
      form.append('phone', business.phone)
      form.append('phoneCountry', business.phoneCountry)
      form.append('email', business.email)
    }
    if (business.address !== '') form.append('address', business.address)
    if (business.lat !== '') form.append('lat', business.lat)
    if (business.lng !== '') form.append('lng', business.lng)
    if (business.about !== '') form.append('about', business.about)
    if (business.imageReader && business.imageReader !== '')
      form.append('image', business.imageReader)
    if (business.categories.length) {
      business.categories.map((catVal: any, index: number) => {
        return form.append('categories[' + index + ']', catVal.reference._id)
      })
    }
    if (business.certificates.length) {
      let changedCertificate: any = []
      business.certificates.map((catVal: any, index: number) => {
        if (catVal.isChanged === true) {
          changedCertificate.push(catVal)
        }
      })
      if (changedCertificate.length) {
        changedCertificate.map((val: any, index: number) => {
          form.append('certificates[' + index + '][reference]', val.reference._id)
          form.append('certificates[' + index + '][expiry]', val.expiry ? val.expiry : '')
          if (val.imageReader) {
            form.append('certificates[' + index + '][file]', val.imageReader)
            form.append('certificates[' + index + '][removeFile]', 'true')
          } else {
            form.append('certificates[' + index + '][removeFile]', 'false')
          }
          return form
        })
      }
    }
    if (business.types.length) {
      business.types.map((businessVal: any, index: number) => {
        return form.append('types[' + index + ']', businessVal.reference._id)
      })
    }
    return form
  },
  VerifyDetails: ({email, phone, phoneCountry}: IVerifyProfile) => {
    if (email) {
      return {email: email}
    }
    if (phone && phoneCountry) {
      return {phone: phone, phoneCountry: phoneCountry}
    }
  },
  UpdateBankDetails: ({
    bankName,
    accountNumber,
    tigoNumber,
    identifierCode,
  }: IUpdateBankDetails) => {
    return {
      bankName: bankName,
      accountNumber: accountNumber,
      tigoNumber: tigoNumber,
      identifierCode: identifierCode,
    }
  },
}
