import moment from 'moment'
const Method = {
  convertNotificationDate: (date: string) => {
    return moment(date).local().format('DD MMM, YYYY [at] LT')
  },
  convertDateToDDMMYYYY: (date: string) => {
    return moment(date).local().format('DD/MM/YYYY ')
  },
  convertDateToDDMMYYYYHHMMAMPM: (date: string) => {
    return moment(date).local().format('DD/MM/YYYY hh:mm A')
  },
  checkisSameOrAfter: (date1: string, date2: string) => {
    return moment(date2).isSameOrAfter(date1)
  },
  dayDifference: (date1: string, date2: string) => {
    return moment(date2).diff(date1, 'days')
  },
  convertDateToFormat: (date: any, format: string) => {
    return moment(date).local().format(format)
  },
  checkIsBefore: (date1: string, date2: string) => {
    return moment(date2).isBefore(date1)
  },
  checkAfter: (date1: string, date2: string) => {
    return moment(date2).isAfter(date1)
  },
  checkAfterTime: (date1: string, date2: string) => {
    return moment(date2).isAfter(date1, 'minutes')
  },
  checkSameDate: (date1: string, date2: string) => {
    return moment(date2).isSame(date1, 'date')
  },
  currentLocalDateTime: () => {
    return moment().format()
  },
  convertDateToDDMMYYYYHHMM: (date: string, seperator?: string) => {
    if (!seperator) return moment(date).local().format('DD/MM/YYYY hh:mm ')
    else {
      let format = 'DD/MM/YYYY [' + seperator + ']  hh:mm'
      return moment(date).local().format(format)
    }
  },
  previousMonthStartDate: (date: string, format: string) => {
    return moment(date).subtract(1, 'month').startOf('month').local().format(format)
  },
  previousMonthEndDate: (date: string, format: string) => {
    return moment(date).subtract(1, 'month').endOf('month').local().format(format)
  },
  getMonth: (date: string, format: any) => {
    return moment(moment(date).month().toString()).format(format)
  },
  getGeneralizedAmount: (amount: any) => {
    return amount.toLocaleString('en-TZ', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    })
  },
  formatNumberWithSuffix: (number: any = 0) => {
    const nonillion = 1e30
    const octillion = 1e27
    const septillion = 1e24
    const sextillion = 1e21
    const quintillion = 1e18
    const quadrillion = 1e15
    const trillion = 1e12
    const billion = 1e9
    const million = 1e6
    const thousand = 1e3
    let returnObj = {
      updatedNumber: number,
      suffixStr: '',
    }
    if (number >= nonillion) {
      returnObj.updatedNumber = number / nonillion
      returnObj.suffixStr = 'No'
    } else if (number >= octillion) {
      returnObj.updatedNumber = number / octillion
      returnObj.suffixStr = 'Oc'
    } else if (number >= septillion) {
      returnObj.updatedNumber = number / septillion
      returnObj.suffixStr = 'Sp'
    } else if (number >= sextillion) {
      returnObj.updatedNumber = number / sextillion
      returnObj.suffixStr = 'Sx'
    } else if (number >= quintillion) {
      returnObj.updatedNumber = number / quintillion
      returnObj.suffixStr = 'Qn'
    } else if (number >= quadrillion) {
      returnObj.updatedNumber = number / quadrillion
      returnObj.suffixStr = 'Q'
    } else if (number >= trillion) {
      returnObj.updatedNumber = number / trillion
      returnObj.suffixStr = 'T'
    } else if (number >= billion) {
      returnObj.updatedNumber = number / billion
      returnObj.suffixStr = 'B'
    } else if (number >= million) {
      returnObj.updatedNumber = number / million
      returnObj.suffixStr = 'M'
    } else if (number >= thousand) {
      returnObj.updatedNumber = number / thousand
      returnObj.suffixStr = 'K'
    }
    return returnObj
  },
  convertToPreviousMonth: (date: string, format: string) => {
    return moment(date).subtract(1, 'month').local().format(format)
  },
  getMonthV2: (date: string, format: any) => {
    return moment(moment(date).month().toString()).format(format)
  },
  populateMissingDates: (dataObject: any, startDate?: any, endDate?: any) => {
    dataObject = dataObject.sort((a: any, b: any) => a.date.localeCompare(b.date))
    let lowestIndex = 0 // Initialize with the first index as the lowest
    let lowestDate = dataObject[0].date // Initialize with the first date as the lowest
    let highestIndex = 0 // Initialize with the first index as the highest
    let highestDate = dataObject[0].date // Initialize with the first date as the highest
    for (let i = 1; i < dataObject.length; i++) {
      if (dataObject[i].date < lowestDate) {
        lowestIndex = i
        lowestDate = dataObject[i].date
      }
      if (dataObject[i].date > highestDate) {
        highestIndex = i
        highestDate = dataObject[i].date
      }
    }
    let temp = [...dataObject]
    const allDates = Array.from(new Set(temp.map((entry: any) => entry.date)))
    // Get the unique months from the dataObject
    const uniqueMonths = Array.from(new Set(allDates.map((date: any) => date.slice(0, 7))))
    // Populate missing dates for each month in the dataObject with zeros
    uniqueMonths.forEach((month) => {
      const monthDates = allDates.filter((date: any) => date.startsWith(month))
      let Start = moment(lowestDate).format('DD')
      let end = moment(highestDate).format('DD')
      for (let i = parseInt(startDate); i <= parseInt(endDate); i++) {
        let monthString = ''
        switch (parseInt(month.slice(5, 7))) {
          case 1:
            monthString = 'Jan'
            break
          case 2:
            monthString = 'Feb'
            break
          case 3:
            monthString = 'Mar'
            break
          case 4:
            monthString = 'Apr'
            break
          case 5:
            monthString = 'May'
            break
          case 6:
            monthString = 'Jun'
            break
          case 7:
            monthString = 'July'
            break
          case 8:
            monthString = 'Aug'
            break
          case 9:
            monthString = 'Sep'
            break
          case 10:
            monthString = 'Oct'
            break
          case 11:
            monthString = 'Nov'
            break
          case 12:
            monthString = 'Dec'
            break
        }
        const day = i < 10 ? `0${i}` : `${i}`
        const date = `${month}-${day}`
        const dateString = `${monthString} ${day}`
        if (!monthDates.includes(date)) {
          temp.push({
            date: date,
            totalSales: 0,
            totalOrders: 0,
            fullDate: dateString,
            customers: 0,
            sellers: 0,
          })
        } else {
          const existingEntryIndex = temp.findIndex((entry: any) => entry.date == date)
          if (existingEntryIndex !== -1) {
            // Add fullDate if it's not already present
            if (!temp[existingEntryIndex].fullDate) {
              temp[existingEntryIndex].fullDate = dateString
            }
          }
        }
      }
    })
    // Sort dataObject based on dates
    return temp.sort((a: any, b: any) => a.date.localeCompare(b.date))
  },
}
export default Method
