import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import CrossSvg from '../../_seller/assets/media/close.png'

export const SkipKycProcess = (props: any) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [currentStep, setCurrentStep] = useState(0)

  useEffect(() => {
    let steps = [false, false, false, false, false]

    if (
      props.kycData.name !== '' &&
      props.kycData.ownerName !== '' &&
      props.kycData.address !== '' &&
      props.kycData.about !== ''
    ) {
      steps[0] = true
    } else {
      steps[0] = false
    }
    if (props.kycData.categories.length) {
      steps[1] = true
    } else {
      steps[1] = false
    }
    if (props.kycData.image !== '') {
      steps[2] = true
    } else {
      steps[2] = false
    }
    if (props.kycData.businessTypes.length) {
      steps[3] = true
    } else {
      steps[3] = false
    }

    let count = 0
    for (let i = 0; i < 4; i++) {
      if (steps[i] === true) {
        count = count + 1
        setCurrentStep(count)
      } else {
        // setCurrentStep(count)
      }
    }
  }, [props.kycData])

  return (
    <>
      <Button variant='' className='btn btn-flush' onClick={handleShow}>
        <span className='fs-16 fw-600 text-gray'>Skip KYC process</span>
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        contentClassName='py-md-5'
        dialogClassName='modal-dialog-centered min-w-lg-590px'
        className='border-r10px'
      >
        <Modal.Header className='border-bottom-0 pb-6'>
          <img
            className='close-inner-top'
            width={40}
            height={40}
            src={CrossSvg}
            alt='closebutton'
            onClick={handleClose}
          />
          <Modal.Title className='mx-auto fs-26 fw-700'>Skip KYC process</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0 pb-4'>
          <div className='text-center px-lg-5'>
            <p className='fs-18 fw-500'>
              You’ve completed <span>{currentStep}</span> / <span>5</span> steps of adding KYC
              details. Are you sure you want to skip adding details?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className='justify-content-center pt-0 border-top-0'>
          <Button className='btn btn-primary p-5' onClick={props.handleSkip}>
            {props.skipLoading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <span className='indicator-label'>Skip KYC process</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

// export {kycRejected}
export default SkipKycProcess
