import {useEffect, useState} from 'react'
import CrossSvg from '../../_seller/assets/media/close.png'
import {Modal, Button, Row, FormLabel, Col, FormControl} from 'react-bootstrap'
import {useAuth} from '../modules/auth'
import APICallService from '../../api/apiCallService'
import {BUSINESS} from '../../api/apiEndPoints'
import {ProfileJson} from '../../api/apiJSON/profile'
import Validations from '../../utils/validations'
import {success} from '../../Global/toast'
import clsx from 'clsx'
export const BankDetails = (props: any) => {
  const [password, setPassword] = useState({
    accountNumber: '',
    identifierCode: '',
    bankName: '',
    tigoNumber: '',
  })
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState({
    accountNumber: false,
    identifierCode: false,
    bankName: false,
    tigoNumber: false,
  })
  const [validation, setValidation] = useState({
    accountNumber: false,
    identifierCode: false,
    bankName: false,
    samePassword: false,
    tigoNumber: false,
  })
  useEffect(() => {
    ;(async () => {
      await fetchDetails()
    })()
  }, [props.show])
  const fetchDetails = async () => {
    // setLoading(true)
   
    // let apiService = new APICallService(BUSINESS.BANKDETAILS, currentUser?.business.profile)
    // let response = await apiService.callAPI()
    // console.log(response)
    // setLoading(false)
  }
  const handleChange = (name: string, value: any) => {
    let temp: any = {...password}
    const tempValidation: any = {...validation}
    temp[name] = value
    tempValidation[name] = false
    if (!value.length) {
      tempValidation[name] = true
    }
    setPassword(temp)
    setValidation(tempValidation)
  }
  const handleSubmit = async () => {
    const tempValidation = {...validation}
    const temp = {...password}
    if (temp.accountNumber == '') {
      tempValidation.accountNumber = true
    }
    if (temp.identifierCode == '') {
      tempValidation.identifierCode = true
    }
    if (temp.bankName == '') {
      tempValidation.bankName = true
    }
    const isValid = await Validations.validateObject(tempValidation)
    if (isValid) {
      setLoading(true)
      let apiService = new APICallService(
        BUSINESS.UPDATEBANKDETAILS,
        ProfileJson.UpdateBankDetails(temp),
        {
          id: currentUser?.business.profile,
        }
      )
      let response = await apiService.callAPI()
      if (response) {
        success('Bank details updated successfully!')
        props.onHide()
      }
      setLoading(false)
    }
    setValidation(tempValidation)
  }
  return (
    <Modal
      {...props}
      show={props.show}
      onHide={props.onHide}
      dialogClassName='modal-dialog-centered min-w-lg-794px'
      className='border-r10px'
      centered
    >
      <Modal.Header className='border-bottom-0 pb-0 text-center mx-auto'>
        <img
          className='close-inner-top'
          width={40}
          height={40}
          src={CrossSvg}
          alt='closebutton'
          onClick={props.onHide}
        />
        <Modal.Title className='fs-26 fw-bolder mw-lg-400px pt-lg-3'>
          Add / Edit bank details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pb-2'>
        <Row className='justify-content-center'>
          <Col md={11}>
            <Row className='gy-6 align-items-center'>
              <Col md={6}>
                <FormLabel className='fs-16 fw-500 text-black required'>Bank name</FormLabel>
                <FormControl
                  className={clsx(
                    'form-control-custom bg-light',
                    validation.bankName ? 'border-1 border-danger' : ''
                  )}
                  placeholder='Type here…'
                  name='bankName'
                  onChange={(e: any) => {
                    handleChange(e.target.name, e.target.value.trimStart())
                  }}
                />
              </Col>
              <Col md={6}>
                <FormLabel className='fs-16 fw-500 text-black required'>Identifier code</FormLabel>
                <FormControl
                  className={clsx(
                    'form-control-custom bg-light',
                    validation.identifierCode ? 'border-1 border-danger' : ''
                  )}
                  placeholder='Type here…'
                  name='identifierCode'
                  onChange={(e: any) => {
                    handleChange(e.target.name, e.target.value.trimStart())
                  }}
                />
              </Col>
              <Col md={6}>
                <FormLabel className='fs-16 fw-500 text-black required'>Account number</FormLabel>
                <FormControl
                  className={clsx(
                    'form-control-custom bg-light',
                    validation.accountNumber ? 'border-1 border-danger' : ''
                  )}
                  placeholder='Type here…'
                  name='accountNumber'
                  onChange={(e: any) => {
                    handleChange(e.target.name, e.target.value.trimStart())
                  }}
                />
              </Col>
              <Col md={6}>
                <FormLabel className='fs-16 fw-500 text-black'>Tigo lipa number</FormLabel>
                <FormControl
                  className={clsx('form-control-custom bg-light')}
                  placeholder='Type here…'
                  name='tigoNumber'
                  onChange={(e: any) => {
                    handleChange(e.target.name, e.target.value.trimStart())
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className='justify-content-center mb-4 border-top-0'>
        <Button
          variant='primary'
          className='btn-active-primary'
          size='lg'
          onClick={() => {
            handleSubmit()
          }}
        >
          {!loading && <span className='indicator-label fs-16 fw-bold'> Add bank details</span>}
          {loading && (
            <span className='indicator-progress fs-16 fw-bold' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default BankDetails
