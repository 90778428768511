import {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import BrandLogo from '../../_seller/assets/media/close.png'
import Calendar from 'react-calendar'
import prev from '../../_seller/assets/media/svg_uTrade/left-arrow.svg'
import next from '../../_seller/assets/media/svg_uTrade/right-arrow.svg'
const ExpiryLicence = (props: any) => {
  const [show, setShow] = useState(true)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [value, onChange] = useState(new Date())
  return (
    <>
      <Modal
        show={props.showExpiry}
        onHide={props.handleHideExpiry}
        dialogClassName='modal-dialog-centered '
        className='border-r10px'
      >
        <Modal.Header className='border-bottom-0 pb-0'>
          <img
            className='img-fluid h-35px w-35px'
            src={BrandLogo}
            alt='closebutton'
            onClick={props.handleHideExpiry}
          />
          <Modal.Title className='mx-auto fs-26 fw-700'>
            Select expiry date for Business License
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='pb-4 pt-5 d-flex justify-content-center'>
          <Calendar
            value={props.value}
            onChange={(e: any) => {
              props.handleExpirySelect(e)
            }}
            minDate={new Date()}
            prev2Label={null}
            tileClassName={'demo'}
            next2Label={null}
            prevLabel={<img src={prev} height={15}></img>}
            nextLabel={<img src={next} height={15}></img>}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
// export {kycRejected}
export default ExpiryLicence
