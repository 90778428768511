import {Form, Formik, FormikValues} from 'formik'
import {FC, useEffect, useRef, useState} from 'react'
import {Button, Card} from 'react-bootstrap'
import {StepperComponent} from '../../../../_seller/assets/ts/components'
import SkipKycProcess from '../../../modals/SkipKycProcess'
import {createAccountSchemas, ICreateAccount, inits} from './CreateAccountWizardHelper'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import BrandLogo from '../../../../_seller/assets/media/uTradeLogo.png'
import {useAuth} from '../core/Auth'
import APICallService from '../../../../api/apiCallService'
import {KYC} from '../../../../api/apiEndPoints'
import PendingKYCDetails from '../../../modals/PendingKYCDetails'
import {KycJson} from '../../../../api/apiJSON/kyc'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import Loader from '../../../../Global/loader'
import {fileValidation} from '../../../../Global/fileValidation'
import {success} from '../../../../Global/toast'
import {kyc} from '../../../../utils/toast'
const KycStepper: FC = () => {
  const navigate = useNavigate()
  const {currentUser, saveCurrentUser} = useAuth()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [showExpiry, setShowExpiry] = useState(false)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [skipLoading, setSkipLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [businessTypes, setBusinessTypes] = useState([])
  const [show, setShow] = useState(false)
  const [businessCertificates, setBusinessCertificate] = useState([])
  const [filteredCertificates, setFilteredCertificate] = useState([])
  const [fetchLoading, setfetchLoading] = useState(false)
  const [currentIndex, setCurrentIndex] = useState<any>()
  const [kycData, setKycData] = useState<{
    name: string
    ownerName: string
    address: string
    lat: string
    lng: string
    about: string
    categories: any
    image: any
    imageReader: any
    businessTypes: any
    businessCertificates: any
    currentStep: number
    submitForReview: boolean
  }>({
    name: '',
    ownerName: '',
    address: '',
    about: '',
    categories: [],
    image: '',
    imageReader: {},
    businessTypes: [],
    businessCertificates: [],
    currentStep: 0,
    submitForReview: false,
    lat: '1',
    lng: '1',
  })
  const [kycValidation, setKycValidation] = useState([])
  useEffect(() => {
    ;(async () => {
      loadStepper()
      await kycInit()
    })()
  }, [])
  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])
  const kycInit = async () => {
    setfetchLoading(true)
    let apiService = new APICallService(KYC.KYCINIT, currentUser?.business.profile)
    let response = await apiService.callAPI()
    if (response) {
      if (!response.business.KYC.submittedEver) {
        let temp = {...kycData}
        temp.name = response.business.name
        temp.ownerName = response.business.ownerName
        temp.address = response.business.address ? response.business.address : ''
        temp.about = response.business.about ? response.business.about : ''
        temp.image = response.business.image ? response.business.image : ''
        if (response.business.categories.length) {
          response.business.categories.map((val: any) => {
            temp.categories.push(val.reference)
          })
        }
        if (response.business.certificates.length) {
          response.business.certificates.map((val: any) => {
            temp.businessCertificates.push({
              image: val.document.url,
              reference: val.reference,
              expiryRequire: val.expiry ? true : false,
              expiry: val.expiry ? moment(val.expiry).format('YYYY-MM-DD') : '',
              expiryCalendar: val.expiry
                ? new Date(moment(val.expiry).local().format())
                : new Date(),
              categories:
                response.defaultItems.businessCertificates[
                  response.defaultItems.businessCertificates.findIndex(
                    (o: any) => o._id === val.reference
                  )
                ].categories,
              isPrefill: true,
            })
          })
        }
        if (response.business.types.length) {
          response.business.types.map((val: any) => {
            temp.businessTypes.push(val.reference)
          })
        }
        if (
          !response.defaultItems.businessCategories.length ||
          !response.defaultItems.businessTypes ||
          !response.defaultItems.businessCertificates
        ) {
          navigate('/message')
        }
        setKycData(temp)
        setCategories(response.defaultItems.businessCategories)
        setBusinessTypes(response.defaultItems.businessTypes)
        setBusinessCertificate(response.defaultItems.businessCertificates)
      } else navigate('/dashboard')
    }
    setfetchLoading(false)
  }
  const handleAddressChange = async (value: string, lat: string, lng: string) => {
    let editDataTemp: any = {...kycData}
    editDataTemp['address'] = value.trimStart()
    editDataTemp['lat'] = lat
    editDataTemp['lng'] = lng
    setKycData(editDataTemp)
  }
  const handleBasicDetailsChange = (name: string, value: string) => {
    let temp: any = {...kycData}
    temp[name] = value.trimStart()
    setKycData(temp)
  }
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex === 3 || stepper.current.currentStepIndex === 5) {
      if (categories.length) {
        stepper.current.goPrev()
      } else {
        stepper.current.goto(1)
      }
    } else {
      stepper.current.goPrev()
    }
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber)
  }
  const handleCategorySelect = (id: string, index: number) => {
    let temp: any = {...kycData}
    if (!temp.categories.includes(id)) {
      temp.categories.push(id)
    } else {
      temp.categories.splice(temp.categories.indexOf(id), 1) //deleting
    }
    setKycData(temp)
  }
  const handleImageChange = (event: any, imageReader: any) => {
    // const selectedFiles = event.target.files
    let temp = {...kycData}
    // if (!selectedFiles) return
    // else {
    //   if (fileValidation(selectedFiles?.[0])) {
    //     temp.image = URL.createObjectURL(selectedFiles?.[0])
    //     temp.imageReader = selectedFiles?.[0]
    //   }
    // }
    if (typeof imageReader === 'string') {
      if (imageReader) {
        let arr: any = imageReader.split(',')
        let mime = arr[0].match(/:(.*?);/)[1]
        let bstr = atob(arr[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        let tempImage = new File([u8arr], temp.name + ' ' + temp.ownerName + ' logo.png', {
          type: 'image/png',
        })
        temp.imageReader = tempImage
      }
    }
    // temp.image = imageReader
    temp.image = event
    setKycData(temp)
  }
  const handleImageDiscard = () => {
    let temp = {...kycData}
    temp.image = ''
    temp.imageReader = {}
    setKycData(temp)
  }
  const handleBusinessTypesSelect = (id: string, index: number) => {
    let temp: any = {...kycData}
    temp.businessTypes = [id]
    setKycData(temp)
  }
  const handleBusinessCertificate = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    id: string,
    expiryRequire: boolean
  ) => {
    const selectedFiles = event.target.files
    let temp = {...kycData}
    if (!selectedFiles) return
    else {
      if (fileValidation(selectedFiles?.[0])) {
        temp.businessCertificates[index].image = URL.createObjectURL(selectedFiles?.[0])
        temp.businessCertificates[index].imageReader = selectedFiles?.[0]
        temp.businessCertificates[index].reference = id
        temp.businessCertificates[index].expiryRequire = expiryRequire
        temp.businessCertificates[index].isPrefill = false
        if (expiryRequire) {
          setCurrentIndex(index)
          setShowExpiry(true)
        }
      }
    }
    setKycData(temp)
  }
  const handleBusinessCertificateDiscard = (index: number, id: string) => {
    let temp = {...kycData}
    temp.businessCertificates[index].image = ''
    temp.businessCertificates[index].imageReader = ''
    temp.businessCertificates[index].reference = ''
    temp.businessCertificates[index].expiry = ''
    temp.businessCertificates[index].expiryRequire = false
    temp.businessCertificates[index].isPrefill = false
    setKycData(temp)
  }
  const handlePendingDetailsClose = () => {
    setShow(!show)
  }
  const handleShowExpiry = (index: number) => {
    setCurrentIndex(index)
    setShowExpiry(true)
  }
  const handleHideExpiry = () => {
    setShowExpiry(false)
  }
  const handleExpirySelect = (value: string, index: number) => {
    let data = {...kycData}
    data.businessCertificates[index].expiry = moment(value).format('YYYY-MM-DD')
    data.businessCertificates[index].expiryCalendar = value
    setKycData(data)
    setShowExpiry(false)
  }
  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    let data = {...kycData}
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      data.currentStep = stepper.current.currentStepIndex
      data.submitForReview = false
      if (stepper.current.currentStepIndex === 4) {
        let businessCertificatesTemp = [...businessCertificates]
        let businessTemp: any = []
        let temp = {...kycData}
        businessCertificatesTemp.map((val: any) => {
          if (val.isPrefill === undefined || val.isPrefill === false) {
            if (val.categories.length) {
              kycData.categories.map((catval: any) => {
                if (val.categories.includes(catval)) {
                  if (businessTemp.length) {
                    if (!businessTemp.some((tempVal: any) => tempVal._id === val._id)) {
                      businessTemp.push(val)
                      temp.businessCertificates.push({
                        image: '',
                        imageReader: '',
                        reference: '',
                        expiry: '',
                        expiryRequire: false,
                        expiryCalendar: new Date(),
                      })
                    }
                  } else {
                    businessTemp.push(val)
                    temp.businessCertificates.push({
                      image: '',
                      imageReader: '',
                      reference: '',
                      expiry: '',
                      expiryRequire: false,
                      expiryCalendar: new Date(),
                    })
                  }
                }
              })
            } else {
              businessTemp.push(val)
              temp.businessCertificates.push({
                image: '',
                imageReader: '',
                reference: '',
                expiry: '',
                expiryRequire: false,
                expiryCalendar: new Date(),
              })
            }
            if (businessTemp.length) {
              setFilteredCertificate(businessTemp)
              setKycData(temp)
            } else {
            }
          } else {
            setFilteredCertificate(kycData.businessCertificates)
            setKycData(temp)
          }
        })
      }
      stepper.current.goNext()
    } else {
      let validate: any = []
      if (kycData.name === '') {
        validate.push('Business Name')
      }
      if (kycData.ownerName === '') {
        validate.push('Name')
      }
      if (kycData.address === '') {
        validate.push('Business address')
      }
      if (kycData.about === '') {
        validate.push('About the business')
      }
      if (!kycData.categories.length) {
        validate.push('Business Category')
      }
      if (kycData.image === '') {
        validate.push('Business Logo')
      }
      if (!kycData.businessTypes.length) {
        validate.push('Business Type')
      }
      if (filteredCertificates.length) {
        // validate.push('Name')
        if (kycData.businessCertificates.length) {
          let filterTemp: any = [...filteredCertificates]
          if (filterTemp.length) {
            kycData.businessCertificates.map((businessVal: any, index: number) => {
              if (businessVal.image === '') {
                if (filterTemp[index]) {
                  if (filterTemp[index].title !== undefined)
                    validate.push(filterTemp[index].title ? filterTemp[index].title : '')
                }
              }
              if (businessVal.expiry === '') {
                if (filterTemp[index]) {
                  if (
                    filterTemp[index].expiryRequire !== undefined &&
                    filterTemp[index].expiryRequire
                  ) {
                    validate.push(
                      filterTemp[index].title
                        ? filterTemp[index].title.trim() + ' ' + 'Expiry Date'
                        : 'Expiry Date'
                    )
                  }
                }
              }
            })
          }
        } else {
          filteredCertificates.map((filterTemp: any, index: number) => {
            validate.push(filterTemp.title)
            if (filterTemp.expiryRequire) {
              validate.push(filterTemp.title.trim() + ' ' + 'Expiry Date')
            }
          })
        }
      }
      if (validate.length) {
        setKycValidation(validate)
        setShow(!show)
      } else {
        setLoading(true)
        let currentStep = stepper.current.currentStepIndex.toString()
        const submitForReview = 'true'
        let apiService = new APICallService(
          KYC.KYCUPDATE,
          KycJson.kycUpdate({kycData, currentStep, submitForReview}),
          {
            id: currentUser?.business.profile,
          }
        )
        let response = await apiService.callAPI()
        if (response) {
          let user: any = currentUser
          user.business.KYC.initiatedEver = true
          user.business.KYC.submittedEver = true
          saveCurrentUser(user)
          success(kyc.submitSuccess)
          navigate('/kyc-submitted')
        }
        setLoading(false)
      }
    }
    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber)
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }
  const handleSkip = async () => {
    setSkipLoading(true)
    const currentStep = stepper.current ? stepper.current.currentStepIndex.toString() : '0'
    const submitForReview = 'false'
    let apiService = new APICallService(
      KYC.KYCUPDATE,
      KycJson.kycUpdate({kycData, currentStep, submitForReview}),
      {
        id: currentUser?.business.profile,
      }
    )
    let response = await apiService.callAPI()
    if (response) {
      let user: any = currentUser
      user.business.KYC.initiatedEver = true
      saveCurrentUser(user)
      navigate('/dashboard')
    }
    setSkipLoading(false)
  }
  return (
    <div className='card position-relative'>
      <div className='kyc-page'>
        <div
          ref={stepperRef}
          className='custom-stepper stepper stepper-links d-flex flex-column'
          id='kt_create_account_stepper'
        >
          {!fetchLoading ? (
            <>
              <Card.Body className='px-md-15'>
                <div className='row align-items-center'>
                  <div className='col-lg-2 col-md-12 '>
                    <img className='img-fluid' height={37} width={131} src={BrandLogo} alt='' />
                  </div>
                  <div className='col-lg-8 col-auto'>
                    <div className='stepper-nav justify-content-lg-center justify-content-md-start'>
                      <div className='stepper-item current' data-kt-stepper-element='nav'>
                        <h3 className='stepper-title'>Step 1 / 5</h3>
                      </div>
                      <div className='stepper-item' data-kt-stepper-element='nav'>
                        <h3 className='stepper-title'>Step 2 / 5</h3>
                      </div>
                      <div className='stepper-item' data-kt-stepper-element='nav'>
                        <h3 className='stepper-title'>Step 3 / 5</h3>
                      </div>
                      <div className='stepper-item' data-kt-stepper-element='nav'>
                        <h3 className='stepper-title'>Step 4 / 5</h3>
                      </div>
                      <div className='stepper-item' data-kt-stepper-element='nav'>
                        <h3 className='stepper-title'>Step 5 / 5</h3>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-2 col-auto text-end pe-0'>
                    <SkipKycProcess
                      steps={stepper.current ? stepper.current.currentStepIndex : 0}
                      handleSkip={handleSkip}
                      skipLoading={skipLoading}
                      kycData={kycData}
                    />
                  </div>
                </div>
              </Card.Body>
              <Formik initialValues={initValues} onSubmit={submitStep}>
                {() => (
                  <Form className='mx-auto w-100' id='kt_create_account_form'>
                    <div className='card-body pt-0'>
                      <PendingKYCDetails
                        show={show}
                        onHide={handlePendingDetailsClose}
                        validation={kycValidation}
                        handleSkip={handleSkip}
                        skipLoading={skipLoading}
                      />
                      <div className='current' data-kt-stepper-element='content'>
                        <Step1
                          basicDetails={kycData}
                          onChange={(name: string, value: string) => {
                            handleBasicDetailsChange(name, value)
                          }}
                          handleAddressChange={handleAddressChange}
                        />
                      </div>
                      <div data-kt-stepper-element='content'>
                        <Step2
                          categories={categories}
                          onCategorySelect={(id: string, index: number) => {
                            handleCategorySelect(id, index)
                          }}
                          selectedCategories={kycData.categories}
                        />
                      </div>
                      <div data-kt-stepper-element='content'>
                        <Step4
                          onImageChange={(e: any, data: any) => handleImageChange(e, data)}
                          image={kycData.image}
                          onImageDiscard={(): void => handleImageDiscard()}
                        />
                      </div>
                      <div data-kt-stepper-element='content'>
                        <Step3
                          businessTypes={businessTypes}
                          onBusinessTypesSelect={(id: string, index: number) => {
                            handleBusinessTypesSelect(id, index)
                          }}
                          selectedBusinessTypes={kycData.businessTypes}
                        />
                      </div>
                      <div data-kt-stepper-element='content'>
                        <Step5
                          businessCertificates={filteredCertificates}
                          onCertificateUpload={(
                            e: React.ChangeEvent<HTMLInputElement>,
                            index: number,
                            id: string,
                            expiryRequire: boolean
                          ): void => handleBusinessCertificate(e, index, id, expiryRequire)}
                          uploadedCertificate={kycData.businessCertificates}
                          onCertificateDiscard={(index: number, id: string): void =>
                            handleBusinessCertificateDiscard(index, id)
                          }
                          handleShowExpiry={(index: number) => {
                            handleShowExpiry(index)
                          }}
                          currentIndex={currentIndex}
                          handleHideExpiry={handleHideExpiry}
                          handleExpirySelect={(value: string, index: number): void =>
                            handleExpirySelect(value, index)
                          }
                          showExpiry={showExpiry}
                        />
                      </div>
                    </div>
                    <div className='card-body kyc-content-footer mh-80px mh-lg-100px '>
                      <div>
                        <button
                          onClick={prevStep}
                          type='button'
                          className='btn btn-lg btnPrev'
                          data-kt-stepper-action='previous'
                          style={{minWidth: '114px'}}
                        >
                          Previous
                        </button>
                      </div>
                      <div>
                        <Button
                          type='submit'
                          className='btn btn-lg btnNext me-3 w-100'
                          style={{minWidth: '114px'}}
                        >
                          {loading ? (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : (
                            <span className='indicator-label'>
                              {!isSubmitButton && 'Next'}
                              {isSubmitButton && 'Submit'}{' '}
                            </span>
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <div className='row card-body align-items-center w-100'>
                <div className='col-lg-2 col-md-12 '>
                  <img className='img-fluid' height={37} width={131} src={BrandLogo} alt='' />
                </div>
              </div>
              <div className='d-flex justify-content-center align-items-center'>
                <Loader loading={fetchLoading} />{' '}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export {KycStepper}
