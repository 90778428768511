/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {Form, InputGroup} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {success} from '../../../../Global/toast'
import Password from '../../../../_seller/assets/media/auth/password.png'
import ShowPassword from '../../../../_seller/assets/media/auth/showPassword.png'
import {PasswordMeterComponent} from '../../../../_seller/assets/ts/components'
import APICallService from '../../../../api/apiCallService'
import {AUTH} from '../../../../api/apiEndPoints'
import {AuthJSON} from '../../../../api/apiJSON/auth'
import {auth} from '../../../../utils/toast'
import {useAuth} from '../core/Auth'
const initialValues = {
  name: '',
  businessName: '',
  email: '',
  phoneNumber: '',
  password: '',
  acceptTerms: false,
}
const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Length should be minimum 3')
    .max(50, 'Length should be maximum 50')
    .required('Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Length should be minimum 3')
    .max(50, 'Length should be maximum 50')
    .required('Email is required'),
  businessName: Yup.string()
    .min(3, 'Length should be minimum 3')
    .max(50, 'Length should be maximum 50')
    .required('Business name is required'),
  phoneNumber: Yup.string()
    .min(6, 'Length should be minimum 6')
    .max(14, 'Length should be maximum 14')
    .required('Phone number is required'),
  password: Yup.string()
    .min(6, 'Length should be minimum 6')
    .max(15, 'Length should be maximum 15')
    .required('Password is required'),
})
export function SignUp() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, saveCurrentUser} = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let apiService = new APICallService(
          AUTH.REGISTER,
          AuthJSON.register({
            email: values.email,
            password: values.password,
            name: values.name,
            phone: values.phoneNumber,
            phoneCountry: '+255',
            businessName: values.businessName,
          })
        )
        let response = await apiService.callAPI()
        if (response) {
          saveAuth(response.token)
          let user = response.user
          saveCurrentUser(user)
          success(auth.register)
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
      }
      setSubmitting(false)
      setLoading(false)
    },
  })
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
   const handleOnKeyPress = (event: any) => {
     var charCode = event.which ? event.which : event.keyCode
     if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
       event.preventDefault()
       return false
     }
     return true
   }
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-left mb-6'>
        <h1 className='text-dark fw-bolder fs-35 mb-3'>
          Create your seller <br className='br' /> Business account!
        </h1>
      </div>
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      <div className='fv-row mb-3'>
        <input
          placeholder='Name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('name')}
          name='name'
          // {...formik.getFieldProps('name')}
          onChange={(e) => {
            formik.handleChange(e)
          }}
          value={formik.values.name.trimStart()}
          className={clsx(
            'form-control form-control-custom',
            {
              'is-invalid': formik.touched.name && formik.errors.name,
            },
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger fs-14 fw-500'>
                {formik.errors.name}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-3'>
        <input
          placeholder='Business name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('businessName')}
          name='businessName'
          onChange={(e) => {
            formik.handleChange(e)
          }}
          value={formik.values.businessName.trimStart()}
          className={clsx(
            'form-control form-control-custom',
            {
              'is-invalid': formik.touched.businessName && formik.errors.businessName,
            },
            {
              'is-valid': formik.touched.businessName && !formik.errors.businessName,
            }
          )}
        />
        {formik.touched.businessName && formik.errors.businessName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger fs-14 fw-500'>
                {formik.errors.businessName}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-3' data-kt-password-meter='true'>
        <div className='mb-1'>
          <InputGroup className='border border-r8px'>
            <InputGroup.Text
              className={clsx(
                ' border-0 fs-16 fw-600 text-black px-6 form',
                {
                  'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber,
                },
                {
                  'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
                }
              )}
            >
              +255
            </InputGroup.Text>
            <Form.Control
              {...formik.getFieldProps('phoneNumber')}
              value={formik.values.phoneNumber.trimStart()}
              onChange={(e) => {
                formik.handleChange(e)
              }}
              onKeyPress={(event: any) => {
                handleOnKeyPress(event)
              }}
              name='phoneNumber'
              className={clsx(
                'form-control form-control-custom border-0 ps-0',
                {
                  'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber,
                },
                {
                  'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
                }
              )}
              aria-label='Default'
              aria-describedby='inputGroup-sizing-default'
              // {...formik.getFieldProps('phoneNumber')}
            />
          </InputGroup>
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger fs-14 fw-500'>
                  {formik.errors.phoneNumber}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='fv-row mb-3'>
        <input
          placeholder='Email'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          name='email'
          value={formik.values.email.trimStart()}
          onChange={(e) => {
            formik.handleChange(e)
          }}
          className={clsx(
            'form-control form-control-custom',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger fs-14 fw-500'>
                {formik.errors.email}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-5'>
        <div className='input-group input-group-solid h-60px border-0 bg-light'>
          <input
            placeholder='Password'
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            value={formik.values.password.trimStart()}
            onChange={(e) => {
              formik.handleChange(e)
            }}
            name='password'
            className={clsx(
              'form-control form-control-custom border-right-0',
              {
                ' border-right-0': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />{' '}
          <span
            className={clsx(
              'input-group-text fs-16 fw-500 text-dark bg-light ',
              {
                ' border-left-0': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid border-0': formik.touched.password && !formik.errors.password,
              }
            )}
            id='basic-addon1'
          >
            {showPassword ? (
              <img
                height={15}
                width={25}
                src={ShowPassword}
                onClick={() => {
                  setShowPassword(!showPassword)
                }}
                alt=''
              ></img>
            ) : (
              <img
                height={15}
                width={25}
                src={Password}
                onClick={() => {
                  formik.touched.password ? setShowPassword(!showPassword) : setShowPassword(false)
                }}
                alt=''
              ></img>
            )}
          </span>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger fs-14 fw-500'>
                {formik.errors.password}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-6'>
        <span className='fs-16 fw-500'>
          By continuing, you agree to
          <Link to='/terms-and-conditions' target='_blank' className='ms-1 link-primary fw-bold'>
            Terms & conditions <br className='br' />
          </Link>
          and
          <Link to='/privacy-policy' target='_blank' className='ms-1 link-primary fw-bold'>
            Privacy policy
          </Link>
          .
        </span>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger fs-14 fw-500'>
                {formik.errors.acceptTerms}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='text-center position-relative'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-primary btn-lg w-100 mb-8'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign up</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div className='auth-footer w-100 text-center'>
          <div className='fs-16 fw-normal'>
            Already have an account?{' '}
            <Link to='/auth/login' className='link-primary fw-bold'>
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </form>
  )
}
