import {useState} from 'react'

import FsLightbox from 'fslightbox-react'

// Media
import uploadSvg from '../../../../../_seller/assets/media/svg_uTrade/upload-file.svg'
import edit from '../../../../../_seller/assets/media/edit-round-large.png'

import {Button, Col} from 'react-bootstrap'
import CrossGray from '../../../../../_seller/assets/media/svg_uTrade/cross-rounded-gray.svg'
import ExpiryLicence from '../../../../modals/LicenseExpiry'
const Step5 = (props: any) => {
  const [toggler, setToggler] = useState(false)
  const [image, setImage] = useState('')
  const [defaultIndex, setIndex] = useState<number>()
  const handleClick = (index: number, image: string) => {
    setToggler(!toggler)
    setImage(image)
    setIndex(index)
  }
  const handleClose = () => {
    setToggler(!toggler)
    setImage('')
    setIndex(-1)
  }
  const expiry = (value: any, index: number) => {
    props.handleExpirySelect(value, index)
  }
  return (
    <div className='kyc-process'>
      {/* content-header */}
      <div className='pb-10 text-center'>
        <h2 className='d-flex justify-content-center fs-34 fw-bolder text-dark'>
          Please upload business certificates
        </h2>
      </div>
      {/* content-header */}

      <div className='mb-10 fv-row'>
        <div className='row gy-6 justify-content-center' data-kt-buttons='true'>
          <FsLightbox
            toggler={toggler}
            disableLocalStorage={true}
            sources={[image]}
            // onClose={() => {
            //   handleClose()
            // }}
            exitFullscreenOnClose={true}
          />

          {props.businessCertificates ? (
            <>
              {props.businessCertificates.map((businessVal: any, index: number) => {
                return props.uploadedCertificate.length ? (
                  props.uploadedCertificate[index].image ? (
                    <Col lg={3} md={4} xl={2} className='mb-5 text-center min-w-lg-250px'>
                      <div className='image-input image-input-outline d-flex flex-center border rounded min-w-225px h-275px'>
                        <div
                          className='image-input-wrapper shadow-none bgi-contain bgi-position-center w-100 h-100'
                          style={{
                            backgroundImage: `url(${props.uploadedCertificate[index].image})`,
                          }}
                        >
                          <img
                            className='close-top'
                            src={CrossGray}
                            alt=''
                            onClick={(): void => props.onCertificateDiscard(index, businessVal._id)}
                          />
                        </div>
                      </div>

                      <div className='d-flex flex-column mt-5'>
                        <span className='fs-20 fw-600'>{businessVal.title}</span>
                        {businessVal.expiryRequire ? (
                          props.uploadedCertificate[index].expiry ? (
                            <>
                              <p className='text-gray fw-600 fs-16 mb-0'>
                                {props.uploadedCertificate[index].expiry}{' '}
                                <label
                                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px '
                                  data-kt-image-input-action='change'
                                  title=''
                                >
                                  <img
                                    className='ms-3'
                                    src={edit}
                                    alt=''
                                    height={24}
                                    width={24}
                                    onClick={() => {
                                      props.handleShowExpiry(index)
                                    }}
                                  />
                                </label>{' '}
                              </p>
                            </>
                          ) : (
                            <Button
                              variant=''
                              className='btn-flush text-primary fw-600'
                              onClick={() => {
                                props.handleShowExpiry(index)
                              }}
                            >
                              Select Expiry date
                            </Button>
                          )
                        ) : (
                          <></>
                        )}
                        <Button
                          variant=''
                          className='btn-flush text-primary fw-600'
                          onClick={() => {
                            handleClick(index, businessVal.image)
                          }}
                        >
                          View sample
                        </Button>
                      </div>
                    </Col>
                  ) : (
                    <Col lg={3} md={4} xl={2} className='mb-5 text-center min-w-lg-250px'>
                      <div className='upload-btn-wrapper border border-r10px d-flex flex-center min-w-225px h-275px'>
                        <input
                          type='file'
                          name='myfile'
                          className='w-100 h-100'
                          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                            props.onCertificateUpload(
                              e,
                              index,
                              businessVal._id,
                              businessVal.expiryRequire
                            )
                          }
                        />
                        <span className='d-flex flex-column align-items-center'>
                          <span className='symbol symbol-60px'>
                            <img className='img-fluid' src={uploadSvg} alt='' />
                          </span>
                          <div className='d-flex flex-column mt-3'>
                            <span className='fs-16 fw-600'>Click to upload</span>
                            <span className='fs-14 fw-normal text-gray'>
                              Maximum file size: 5 MB
                            </span>
                          </div>
                        </span>
                      </div>
                      <div className='d-flex flex-column mt-3'>
                        <span className='fs-20 fw-600'>{businessVal.title}</span>
                        <Button
                          variant=''
                          className='btn-flush text-primary fw-600'
                          onClick={() => {
                            handleClick(index, businessVal.image)
                          }}
                        >
                          View sample
                        </Button>

                        {/* <FsLightbox
                        disableLocalStorage={true}
                        toggler={toggler && defaultIndex === index}
                        sources={[businessVal.image]}
                        onClose={() => {
                          handleClose()
                        }}
                        exitFullscreenOnClose={true}
                      /> */}
                      </div>
                    </Col>
                  )
                ) : (
                  <></>
                )
              })}


              {props.uploadedCertificate[props.currentIndex] &&
              props.uploadedCertificate[props.currentIndex].expiryCalendar !== undefined ? (
                <ExpiryLicence
                  showExpiry={props.showExpiry}
                  handleHideExpiry={props.handleHideExpiry}
                  handleExpirySelect={(value: string) => {
                    expiry(value, props.currentIndex)
                    // props.handleExpirySelect(value, props.currentIndex)
                  }}
                  value={
                    props.uploadedCertificate[props.currentIndex].expiryCalendar
                      ? props.uploadedCertificate[props.currentIndex].expiryCalendar
                      : props.businessCertificates.map((businessVal: any) => {
                          return businessVal[props.currentIndex].expiryCalendar
                        })
                  }
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export {Step5}
