/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../_seller/assets/ts/_utils'
import {useThemeMode} from '../../../../_seller/partials'
import {Button, Card} from 'react-bootstrap'
import {CustomSelect} from '../../../custom/Select/CustomSelect'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {PAGE_LIMIT} from '../../../../utils/constants'
import APICallService from '../../../../api/apiCallService'
import {DEFAULT, ordersDelivery} from '../../../../api/apiEndPoints'
import Method from '../../../../utils/methods'
import Loader from '../../../../Global/loader'
const RecentOrders = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [orders, setOrders] = useState<any>({})
  const [page, setPage] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)
  const [loading, setLoading] = useState(false)
  const [pageLimit, setPageLimit] = useState(5)
  const [districts, setDistricts] = useState([])
  const [selectedDistrict, setSelectedDistrict] = useState<any>()
  useEffect(() => {
    ;(async () => {
      await fetchDetails(page, pageLimit, [1], undefined)
      await fetchDistrict()
    })()
  }, [])
  const fetchDistrict = async () => {
    let params = {}
    let apiService = new APICallService(DEFAULT.DISTRICTS, params)
    let response: any = await apiService.callAPI()
    let data: any = []
    response.records.map((val: any) => {
      data.push({
        value: val._id,
        label: val.name,
        title: val.name,
        _id: val._id,
        name: val.name,
      })
    })
    setDistricts(data)
  }
  const fetchDetails = async (pageNo: number, limit: number, status?: any, districtId?: any) => {
    setLoading(true)
    let params: any = {
      pageNo: pageNo,
      limit: limit,
      sortKey: '_createdAt',
      sortOrder: -1,
      needCount: pageNo === 1,
    }
    if (status.length > 0) {
      status.map((val: any, index: number) => {
        params = {...params, listType: val}
      })
    }
    if (districtId !== undefined) {
      params = {...params, district: districtId._id}
    }
    let apiService = new APICallService(ordersDelivery.list, params, {
      id: currentUser?.business.profile,
    })
    let response: any = await apiService.callAPI()
    if (response.records) {
      if (pageNo === 1) {
        setTotalRecords(response.total)
      } else {
        let prevTotal = totalRecords
        setTotalRecords(prevTotal)
      }
      setOrders(response.records)
    }
    setLoading(false)
  }
  const handleDistrictChange = async (event: any) => {
    if (event) {
      setSelectedDistrict(event)
      await fetchDetails(page, pageLimit, [1], event)
    } else {
      setSelectedDistrict({})
      await fetchDetails(page, pageLimit, [1], undefined)
    }
  }
  return (
    <>
      <Card className='border border-r10px bg-f9f9f9 mb-7'>
        <Card.Header className='pt-7 border-bottom-0'>
          <div className='card-title'>
            <h2 className='fs-22 fw-bolder'>Recent orders</h2>
          </div>
          <div className='card-toolbar'>
            <div className='min-w-md-193px'>
              <CustomSelect
                backgroundColor='#ffff'
                minHeight='50px'
                disabled={loading}
                default={selectedDistrict}
                options={districts}
                loadingMessage={'Fetching Data'}
                isClearable={true}
                onChange={(e: any) => handleDistrictChange(e)}
              />
            </div>
            <div className='ms-3'>
              <Button
                variant='link'
                className='btn-flush text-primary fs-16 fw-bold'
                onClick={() => navigate('/orders')}
              >
                View all
              </Button>
            </div>
          </div>
        </Card.Header>
        {!loading ? (
          <>
            {orders && orders.length ? (
              <>
                <Card.Body className='pt-4'>
                  <div className='border border-r10px bg-white'>
                    <div className='table-responsive'>
                      <table className='table table-row-bordered table-rounded align-middle gs-7 gy-4 my-0'>
                        <thead>
                          <tr className='fs-16 fw-600 h-65px align-middle'>
                            <th className='min-w-175px'>Order Date & ID</th>
                            <th className='min-w-150px'>Quantity</th>
                            <th className='min-w-100px'>Total amount</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            {orders.map((orderVal: any, index: number) => (
                              <tr>
                                <td>
                                  <span className='text-dark fw-500 fs-15 d-block'>
                                    {Method.convertDateToDDMMYYYYHHMM(orderVal._createdAt, '-')}
                                  </span>
                                  <span className='text-gray fw-500 fs-15 d-block'>
                                    {orderVal.refKey}
                                  </span>
                                </td>
                                <td>
                                  <span className='fs-15 fw-500'>
                                    {' '}
                                    {orderVal.orderedQuantities + ' items'}
                                  </span>
                                </td>
                                <td>
                                  <span className='fs-15 fw-500'>
                                    {`TSh ${Method.getGeneralizedAmount(
                                      orderVal.payment.totalCharge
                                    )}`}
                                  </span>
                                </td>
                                <td className='text-end'>
                                  <Button
                                    className='fs-14 fw-600'
                                    onClick={() =>
                                      navigate('/orders/order-details', {
                                        state: orderVal._id,
                                      })
                                    }
                                  >
                                    View details
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Card.Body>
              </>
            ) : (
              <>
                <Card.Body>
                  <div className='d-flex flex-center min-h-140px'>
                    <span className='fs-18 fw-medium'>
                      No order details available for this month.
                    </span>
                  </div>
                </Card.Body>
              </>
            )}
          </>
        ) : (
          <>
            <Card.Body>
              <div className='d-flex flex-center min-h-140px'>
                <Loader loading={loading} />
              </div>
            </Card.Body>
          </>
        )}
      </Card>
    </>
  )
}
export default RecentOrders
