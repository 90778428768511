/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import Loader from '../../../Global/loader'
import Verified from '../../../_seller/assets/media/svg_uTrade/verified.svg'
import {PageTitle} from '../../../_seller/layout/core'
import APICallService from '../../../api/apiCallService'
import {AUTH, KYC} from '../../../api/apiEndPoints'
import {KycJson} from '../../../api/apiJSON/kyc'
import {Accepted, InfoRequired, Rejected, Submitted, UnderReview} from '../../../utils/constants'
import {IMAGES} from '../../../utils/staticJSON'
import {Dashboard} from '../../../utils/string'
import EditKYCDetails from '../../modals/EditKYCDetails'
import {useAuth} from '../../modules/auth'
import RecentOrders from './chart/recent-orders'
import TopSellingProducts from './chart/top-selling-products'
import OrdersReport from './chart/orders-report'
import SalesReportChart from './chart/sale-report'
const DashboardPage = () => {
  const {currentUser, saveCurrentUser} = useAuth()
  const navigate = useNavigate()
  const [dashboardData, setDashboardData] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [timer, setTimer] = useState(0)
  const [show, setShow] = useState(false)
  const [showProceed, setShowProceed] = useState(false)
  useEffect(() => {
    ;(async () => {
      await fetchDashboardData()
    })()
  }, [])
  const fetchDashboardData = async () => {
    setLoading(true)
    let apiService = new APICallService(AUTH.DASHBOARD)
    let response = await apiService.callAPI()
    if (response) {
      if (response.user.business?.KYC.submittedEver || response.user.business?.KYC.initiatedEver) {
        if (
          response.user.business?.KYC.status === Accepted &&
          !response.user.business?.KYC.approvalRead
        ) {
          setTimer(5)
          setShow(true)
        } else if (response.user.business?.KYC.status === Rejected) {
          navigate('/kyc-rejected')
        }
      } else {
        navigate('/kyc-process')
      }
      saveCurrentUser(response.user)
      setDashboardData(response.user)
    }
    setLoading(false)
  }
  useEffect(() => {
    let timeLeft = timer
    if (show && timeLeft === 0) {
      ;(async () => {
        await statusRead()
      })()
    }
    if (!timeLeft) return
    const intervalId = setInterval(() => {
      setTimer(timeLeft - 1)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [timer])
  const statusRead = async () => {
    let status = '1'
    let apiService = new APICallService(KYC.KYCREAD, KycJson.kycRead({status}), {
      id: currentUser?.business.profile,
    })
    let response = await apiService.callAPI()
    if (response) {
      setTimer(0)
      setShow(false)
    }
  }
  const handleEdit = () => {
    navigate('/profile-management/manage-profile', {state: {edit: true}})
  }
  const handleProceedShow = () => {
    setShowProceed(true)
  }
  return (
    <>
      <Row className='gy-5 g-xl-8'>
        <Col xl={12} className='mb-3'>
          <div className='mb-8'>
            <h1 className='fs-22 fw-700'>
              {' '}
              {Dashboard.welcome} {currentUser?.business.name}{' '}
            </h1>
          </div>
          {loading ? (
            <div className='d-flex justify-content-center'>
              <Loader loading={loading} />
            </div>
          ) : dashboardData ? (
            <>
              <EditKYCDetails
                show={showProceed}
                onHide={() => {
                  setShowProceed(false)
                }}
                handleEdit={handleEdit}
                dashboardData={dashboardData}
              />
              {dashboardData.business?.KYC.submittedEver ? (
                dashboardData.business?.KYC.status === Submitted ? (
                  <>
                    <div className='custom-alert alert bg-light-warning border-warning mb-5'>
                      <Row className='align-items-center p-3'>
                        <Col>
                          <h4 className='alert-heading'>{Dashboard.businessDetailsReview}</h4>
                          <p className='mb-md-0'>{Dashboard.stayTuned}</p>
                        </Col>
                        <Col md='auto'>
                          <button
                            type='submit'
                            id='kt_sign_in_submit'
                            className='btn btn-primary btn-lg w-100'
                            onClick={() => {
                              // navigate('/profile-management/manage-profile', {state: {edit: true}})
                              handleProceedShow()
                            }}
                          >
                            <span className='indicator-label'>{Dashboard.editKyc}</span>
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : dashboardData.business?.KYC.status === UnderReview ? (
                  <div className='custom-alert alert bg-light-warning border-warning mb-5'>
                    <Row className='align-items-center p-3'>
                      <Col>
                        <h4 className='alert-heading'>{Dashboard.businessDetailsReview}</h4>
                        <p className='mb-md-0'>{Dashboard.stayTuned}</p>
                      </Col>
                      <Col md='auto'>
                        <button
                          type='button'
                          id='kt_sign_in_submit'
                          className='btn btn-primary btn-lg w-100'
                          onClick={() => {
                            handleProceedShow()
                          }}
                        >
                          <span className='indicator-label'>{Dashboard.editKyc}</span>
                        </button>
                      </Col>
                    </Row>
                  </div>
                ) : dashboardData.business?.KYC.status === InfoRequired ? (
                  <>
                    <div className='custom-alert alert bg-light-danger border-danger mb-5'>
                      <Row className=' align-items-center p-3'>
                        <Col>
                          <h4 className='alert-heading text-danger'>{Dashboard.moreInformation}</h4>
                          <p className='mb-md-0'>{dashboardData.business?.KYC.statusMessage}</p>
                        </Col>
                        <Col md='auto'>
                          <button
                            type='submit'
                            id='kt_sign_in_submit'
                            className='btn btn-primary btn-lg w-100'
                            onClick={() => {
                              handleProceedShow()
                            }}
                          >
                            <span className='indicator-label'>{Dashboard.editKyc}</span>
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : dashboardData.business?.KYC.status === Accepted ? (
                  show ? (
                    <>
                      <div className='custom-alert alert bg-light-success border-success mb-5'>
                        <Row className=' align-items-center p-3'>
                          <Col sm>
                            <h4 className='alert-heading '>{Dashboard.congrats}</h4>
                            <p className='mb-md-0'>{Dashboard.startSelling}</p>
                          </Col>
                          <Col sm='auto'>
                            <button
                              type='button'
                              id='kt_sign_in_submit'
                              className='btn btn-icon  w-100'
                            >
                              <img alt='Logo' src={Verified} className='h-40px' />
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )
              ) : (
                <div className='custom-alert alert bg-light-danger border-danger mb-5'>
                  <Row className=' align-items-center p-3'>
                    <Col>
                      <h4 className='alert-heading'>{Dashboard.completeBusiness}</h4>
                      <p className='mb-md-0'>{Dashboard.addKyc}</p>
                    </Col>
                    <Col md='auto'>
                      <Link
                        to='/kyc-process'
                        id='kt_sign_in_submit'
                        className='btn btn-primary btn-lg w-100'
                      >
                        <span className='indicator-label'>{Dashboard.completeKyc}</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <SalesReportChart />
      <OrdersReport />
      <TopSellingProducts />
      <RecentOrders />
    </>
  )
}
const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <div className='layout-padding my-7'>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Menu.Dashboard'})}</PageTitle>
      <DashboardPage />
    </div>
  )
}
export {DashboardWrapper}
