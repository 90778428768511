import {FC, createContext, useContext, useState, useEffect, SetStateAction, Dispatch} from 'react'
import {WithChildren} from '../../helpers'
import {useAuth} from '../../../app/modules/auth'
const SellerSplashScreenContext = createContext<Dispatch<SetStateAction<number>> | undefined>(
  undefined
)
const SellerSplashScreenProvider: FC<WithChildren> = ({children}) => {
  const [count, setCount] = useState(0)
  let visible = count > 0
  useEffect(() => {
    const splashScreen = document.getElementById('splash-screen')
    // Show SplashScreen
    if (splashScreen && visible) {
      splashScreen.classList.remove('hidden')
      return () => {
        splashScreen.classList.add('hidden')
      }
    }
    // Hide SplashScreen
    let timeout: number
    if (splashScreen && !visible) {
      timeout = window.setTimeout(() => {
        splashScreen.classList.add('hidden')
      }, 3000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [visible])
  return (
    <SellerSplashScreenContext.Provider value={setCount}>
      {children}
    </SellerSplashScreenContext.Provider>
  )
}
const LayoutSplashScreen: FC<{visible?: boolean}> = ({visible = true}) => {
  // Everything are ready - remove splashscreen
  const setCount = useContext(SellerSplashScreenContext)
  const {currentUser} = useAuth()
  // useEffect(() => {
  //   ;(async () => {
  //     if (currentUser && (await GlobalValidations.checkNetConnection())) {
  //       let params = {
  //         needCount: false,
  //         categoriesDepth: 3,
  //       }
  //       let apiService = new APICallService(DEFAULT.CATEGORIES, defaultJson.listCategory(params))
  //       let response = await apiService.callAPI()
  //       if (response) {
  //         secureLocalStorage.setItem('categories', JSON.stringify(response.records))
  //       }
  //       let businessApiService = new APICallService(DEFAULT.BUSINESSTYPES, params)
  //       let businessresponse = await businessApiService.callAPI()
  //       if (businessresponse) {
  //         secureLocalStorage.setItem('businessTypes', JSON.stringify(businessresponse.records))
  //       }
  //       let certificatesApiService = new APICallService(DEFAULT.CERTIFICATES)
  //       let certificateResponse = await certificatesApiService.callAPI()
  //       if (certificateResponse) {
  //         secureLocalStorage.setItem(
  //           'businessCertificates',
  //           JSON.stringify(certificateResponse.records)
  //         )
  //       }
  //     }
  //   })()
  // }, [])
  useEffect(() => {
    if (!visible) {
      return
    }
    if (setCount) {
      setCount((prev) => {
        return prev + 1
      })
    }
    return () => {
      if (setCount) {
        setCount((prev) => {
          return prev - 1
        })
      }
    }
  }, [setCount, visible])
  return null
}
export {SellerSplashScreenProvider, LayoutSplashScreen}
