import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import CrossSvg from '../../_seller/assets/media/close.png'
import { Card } from 'react-bootstrap'

const PendingKYCDetails = (props: any) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        contentClassName="pb-5 border-r10px"
        dialogClassName='modal-dialog-centered min-w-lg-590px'
      >
        <Modal.Header className='border-bottom-0'>
          <img
            className='close-inner-top-2'
            width={40}
            height={40}
            src={CrossSvg}
            alt='closebutton'
            onClick={props.onHide}
          />
          <Modal.Title className='mx-auto fs-26 fw-700'>Details pending</Modal.Title>
        </Modal.Header>

        <Modal.Body className='pt-0 pb-4'>
          <Card>
            <Card.Body className='pb-4'>
              <div className='text-center px-lg-5'>
                <p className='fs-18 fw-500'>
                  Please add following details to complete your business KYC verification.
                </p>
              </div>
              <div className='details-pending overflow-auto mh-275px'>
                {props.validation &&
                  props.validation.map((val: string) => {
                    return <div className='details'>{val}</div>
                  })}
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>

        <Modal.Footer className='justify-content-center pt-0 border-top-0'>
          <Button onClick={props.handleSkip} className='btn btn-primary p-5'>
            {props.skipLoading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <span className='indicator-label'>Skip KYC process</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PendingKYCDetails
