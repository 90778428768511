/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import OtpInput from '../../../custom/otpInput/OtpInput'
import APICallService from '../../../../api/apiCallService'
import {AUTH} from '../../../../api/apiEndPoints'
import {auth} from '../../../../utils/toast'
import {useAuth} from '../core/Auth'
import BrandLogo from '../../../../_seller/assets/media/uTradeLogo.png'
import {success} from '../../../../Global/toast'
const VerifyOTP = () => {
  const navigate = useNavigate()
  const {currentUser, saveCurrentUser} = useAuth()
  const [otp, setOtp] = useState([])
  const [timer, setTimer] = useState(60)
  const [resendLoading, setResendLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const inputRefs: any = useRef([])
  const handleChange = (value: string, index: number) => {
    let temp: any = [...otp]
    temp[index] = value
    if (value) {
      index === 3 ? inputRefs.current[0].focus() : inputRefs.current[index + 1].focus()
    }
    setOtp(temp)
  }
  const handleResend = async () => {
    if (!timer) {
      setResendLoading(true)
      let apiService = new APICallService(AUTH.RESEND)
      let response = await apiService.callAPI()
      if (response) {
        success(auth.resendOTP)
      }
      setTimer(60)
      setResendLoading(false)
    }
    // handleVerify()
  }
  useEffect(() => {
    inputRefs.current[0].focus()
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])
  useEffect(() => {
    let timeLeft = timer
    if (timeLeft === 0) {
      setTimer(0)
    }
    if (!timeLeft) return
    const intervalId = setInterval(() => {
      setTimer(timeLeft - 1)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [timer])
  const handleSubmit = async () => {
    setLoading(true)
    let apiService = new APICallService(AUTH.VERIFY, {code: otp.join('')})
    let response = await apiService.callAPI()
    if (response) {
      if (currentUser) {
        currentUser.emailVerified = true
        saveCurrentUser(currentUser)
        success(auth.verifiedEmail)
        let temp: any = window
        temp.location = temp.location.protocol + '//' + temp.location.host + '/welcome'
        navigate('/welcome')
      }
    }
    setOtp([])
    // setTimer(30)
    setLoading(false)
  }
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-lg-row-fluid py-10'>
        <div className=''>
          <Link to='/' className='mb-12 position-absolute top-0 start-0 mt-10 ms-10'>
            <img alt='Logo' src={BrandLogo} className='h-40px' />
          </Link>
        </div>
        <div className='d-flex flex-center pt-lg-0 pt-15 flex-column flex-column-fluid'>
          <div className='w-lg-550px p-8 p-md-10 p-lg-15 mx-auto'>
            <form className='form w-100' action='#'>
              <div className='mb-4 text-center'>
                <h1 className='text-dark fs-36 mb-3 fw-600'>Verify your account</h1>
                <div className='text-black fs-16 fw-400'>
                  {/* We’ve sent an OTP to <span>wadsworth@email.com.</span>
                    Please enter it below to reset the password. */}
                  We’ve sent an OTP to {currentUser?.phoneCountry} {currentUser?.phone}
                  <br></br>
                  and {currentUser?.email}
                </div>
              </div>
              <div className='d-flex flex-wrap mb-2 justify-content-center'>
                <div className='d-flex flex-wrap'>
                  <OtpInput
                    value={otp}
                    valueLength={4}
                    onChange={(value: string, index: number) => {
                      handleChange(value, index)
                    }}
                    inputRefs={inputRefs}
                  />
                </div>
              </div>
              <div className='text-center pt-3'>
                <button
                  type='button'
                  className='btn btn-primary br-8 w-75'
                  onClick={handleSubmit}
                  disabled={otp.length < 4}
                >
                  {loading ? (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    <span className='indicator-label fs-16 fw-600'>Verify OTP</span>
                  )}
                  {/* <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span> */}
                </button>
              </div>
              <div className='fs-14 fw-400 text-center pt-3 d-flex justify-content-center'>
                {resendLoading ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                  </span>
                ) : (
                  <p
                    className='fs-14 fw-400 me-2'
                    style={timer ? {} : {cursor: 'pointer'}}
                    onClick={handleResend}
                  >
                    Resend OTP
                  </p>
                )}
                <span className='fs-14 fw-400 '>
                  {timer ? (timer < 10 ? ' in 00:0' + timer : ' in 00:' + timer) : ''}
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default VerifyOTP
