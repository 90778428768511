import {Link} from 'react-router-dom'
// Media
import BrandLogo from '../../../../../_seller/assets/media/uTradeLogo.png'
const KycSubmitted = () => {
  return (
    <>
      <div className='d-flex flex-column flex-row-fluid py-10'>
        <div className='px-md-15 px-8'>
          <Link to='/' className='mb-12 position-absolute top-0 start-0 mt-10 ms-10'>
            <img alt='Logo' src={BrandLogo} className='h-40px' />
          </Link>
        </div>
        <div className='d-flex flex-center flex-column flex-column-fluid pt-lg-0 pt-15'>
          <div className='d-flex justify-content-center align-items-center bg-light-warning p-3 px-4 pills-radius'>
            <span className='bullet bullet-dot bg-warning me-2 h-10px w-10px'></span>
            <span className='fw-600 text-black fs-18'>Business details in review</span>
          </div>
          <div className='text-center '>
            <div className=' py-7'>
              <h1 className='text-dark fs-36 fw-700'>
                Thank you for submitting your business details
              </h1>
              <h1 className='text-dark fs-36 fw-700'>You can start listing your products</h1>
            </div>
          </div>
          <div className='text-center'>
            <Link to='/dashboard' className='btn btn-lg btn-primary p-6'>
              <span className='indicator-label fs-16'>Let's get started!</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
// export {kycRejected}
export default KycSubmitted
