import { Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { contactUs, Dashboard } from '../../utils/string'
import CrossSvg from '../../_seller/assets/media/close.png'

const ContactUs = (props: any) => {
  return (
    <>
      {/* <Button variant='primary' onClick={handleShow}>
        Launch demo modal
      </Button> */}
      <Button className='btn btn-lg btn-light-primary p-6' onClick={props.handleShow}>
        <span className='indicator-label fs-16'>{Dashboard.contactus}</span>
      </Button>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        contentClassName='py-md-5'
        dialogClassName='modal-dialog-centered min-w-lg-590px'
        className='border-r10px'
      >
        <Modal.Header className='border-bottom-0 pb-6'>
          <img
            className='close-inner-top'
            width={40}
            height={40}
            src={CrossSvg}
            alt='closebutton'
            onClick={props.handleClose}
          />
          <Modal.Title className='mx-auto fs-26 fw-700'>{contactUs.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pb-4 pt-5'>
          <div className='text-center px-lg-5'>
            <p className='fs-18 fw-500'>{contactUs.message}</p>
          </div>
          <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
            <Form.Control
              as='textarea'
              rows={5}
              placeholder='Type your message here…'
              // onChange={props.handleMessage}
              value={props.message}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                props.handleMessage(e.target.value)
              }
            />
          </Form.Group>
          {/* <textarea
            className='form-control form-control-solid'
            name='contact-us'
            id='contact-us'
            placeholder='Type your message here…'
          ></textarea> */}
        </Modal.Body>
        <Modal.Footer className='justify-content-center pt-0 border-top-0'>
          <Button variant='primary' className='p-6' onClick={props.sendFeedback}>
            {/* {contactUs.sendMessage} */}
            {props.loading ? (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <span className='indicator-label fs-16 fw-600'>{contactUs.sendMessage}</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

// export {kycRejected}
export default ContactUs
