/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Button, Card} from 'react-bootstrap'
import {CustomSelect} from '../../../custom/Select/CustomSelect'
import CustomDatePicker from '../../../custom/DateRange/DatePicker'
import {IMAGES} from '../../../../utils/staticJSON'
import {useNavigate} from 'react-router-dom'
import {
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  PAGE_LIMIT,
  Piece,
} from '../../../../utils/constants'
import {DEFAULT, dashboard} from '../../../../api/apiEndPoints'
import APICallService from '../../../../api/apiCallService'
import Method from '../../../../utils/methods'
import {useAuth} from '../../../modules/auth'
import Loader from '../../../../Global/loader'
import {CustomComponentSelect} from '../../../custom/Select/CustomComponentSelect'
const TopSellingProducts = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [startDate, setStartDate] = useState<any>(null)
  const [sellerData, setSellerData] = useState<any>([])
  const [filterProducts, setFilterProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedDistrict, setSelectedDistrict] = useState<any>()
  const [endDate, setEndDate] = useState<any>(null)
  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(PAGE_LIMIT)
  const [categories, setCategories] = useState<any>([])
  useEffect(() => {
    ;(async () => {
      await fetchReports(startDate, endDate, selectedDistrict, page, pageLimit, categories, '')
      await fetchCategory()
    })()
  }, [])
  const fetchReports = async (
    fromDate: any,
    toDate: any,
    district: any,
    pageNo: number,
    limit: number,
    categories: any,
    searchValue: string
  ) => {
    setLoading(true)
    let params: any = {
      pageNo: pageNo,
      limit: limit,
      needCount: true,
      searchTerm: searchValue,
      utcOffset: -new Date().getTimezoneOffset(),
      reportType: '0',
    }
    if (district !== undefined) {
      params = {...params, 'districts[]': district._id}
    }
    if (categories !== undefined && categories.length) {
      categories.map((val: any, index: number) => {
        params = {...params, ['categories[' + index + ']']: val.id}
      })
    }
    if (fromDate && toDate) {
      params = {
        ...params,
        fromDate: Method.convertDateToFormat(fromDate, 'YYYY-MM-DD'),
        toDate: Method.convertDateToFormat(toDate, 'YYYY-MM-DD'),
      }
    }
    let apiService = new APICallService(dashboard.topSellingProducts, params, {
      id: currentUser?.business.profile,
    })
    let response = await apiService.callAPI()
    setSellerData(response.records)
    setLoading(false)
  }
  const fetchCategory = async () => {
    let apiService = new APICallService(DEFAULT.CATEGORIES, {
      categoriesDepth: 1,
    })
    let response = await apiService.callAPI()
    if (response.records) {
      let temp: any = []
      response.records.map((val: any) => {
        temp.push({
          value: val.title,
          name: val.title,
          title: val.title,
          id: val._id,
          label: (
            <>
              <div className='symbol symbol-40px symbol-circle border me-2'>
                <img src={val.image} className='object-fit-contain' alt='' />
              </div>
              <label className='form-check-label truncate-2 fs-16 fw-600 text-dark' htmlFor='Ex2'>
                {val.title}
              </label>
            </>
          ),
        })
      })
      setFilterProducts(temp)
    }
  }
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate)
    setEndDate(endDate)
    if (startDate && endDate) {
      await fetchReports(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        selectedDistrict,
        page,
        pageLimit,
        categories,
        ''
      )
    } else {
      await fetchReports(null, null, selectedDistrict, page, pageLimit, categories, '')
    }
  }
  const handleCategoryFilter = async (event: any) => {
    setCategories(event)
    setPage(1)
    await fetchReports(startDate, endDate, selectedDistrict, page, pageLimit, event, '')
  }
  return (
    <>
      <Card className='border border-r10px bg-f9f9f9 mb-7'>
        <Card.Header className='pt-7 border-bottom-0'>
          <div className='card-title'>
            <h2 className='fs-22 fw-bolder'>Top selling products</h2>
          </div>
          <div className='card-toolbar'>
            <div className='min-w-md-300px'>
              <CustomComponentSelect
                isDisabled={loading}
                hideSelectedOptions={false}
                options={filterProducts}
                onChange={(event: any) => {
                  handleCategoryFilter(event)
                }}
                isMulti={true}
              />
            </div>
            <div className='min-w-lg-300px mw-lg-300px ms-3'>
              <CustomDatePicker
                className='bg-white min-h-50px fs-16 fw-bold '
                // selected={startDate}
                onChange={handleChange}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat='dd/MM/yyyy'
                showFullMonthYearPicker={true}
                maxDate={new Date()}
                isClearable={true}
              />
            </div>
            <div className='ms-3'>
              <Button
                variant='link'
                className='btn-flush text-primary fs-16 fw-bold'
                onClick={() => navigate('/catalogue/top-selling-products')}
              >
                View all
              </Button>
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-4'>
          <div className='border border-r10px bg-white'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-rounded align-middle gs-7 gy-4 my-0'>
                <thead>
                  <tr className='fs-16 fw-600 h-65px align-middle'>
                    <th className='min-w-175px'>Product name</th>
                    <th className='min-w-150px'>Unit sold</th>
                    <th className='min-w-100px'>Total orders</th>
                    <th className='min-w-100px'>Total sales</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading ? (
                    <>
                      {sellerData && sellerData.length ? (
                        <>
                          {sellerData.slice(0, 5).map((val: any) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='symbol symbol-50px me-3'>
                                        <img src={val.variant.media[0].url} className='' alt='' />
                                      </div>
                                      <span className='fw-600 fs-15'>{val.variant.title}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='badge badge-light border-r23px'>
                                      <span className='fs-15 fw-600 text-dark p-3 d-block'>
                                        {val.quantityTypes
                                          .map((item: any) => {
                                            if (
                                              item.type === CartonWithDozens ||
                                              item.type === CartonWithPieces
                                            ) {
                                              return `${item.stockCount} C`
                                            }
                                            if (item.type === Dozen) {
                                              return `${item.stockCount} D`
                                            }
                                            if (item.type === Piece) {
                                              return `${item.stockCount} P`
                                            }
                                            return ''
                                          })
                                          .join(', ')}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <span className='fs-15 fw-600'>{val.totalOrders}</span>
                                  </td>
                                  <td>
                                    <span className='fs-15 fw-600'>
                                      {`TSh ${Method.getGeneralizedAmount(
                                        parseInt(val.totalSales)
                                      )}`}
                                    </span>
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colSpan={4}>
                              <div className='d-flex justify-content-center'>
                                <span className='fs-18 fw-medium'>
                                  No details available for this month.
                                </span>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan={4}>
                          <div className='d-flex justify-content-center'>
                            <Loader loading={loading} />
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}
export default TopSellingProducts
