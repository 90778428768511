import {Button, Card, Col, Row} from 'react-bootstrap'
import logo from '../../../../_seller/assets/media/uTradeLogo.png'
import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
const PrivacyPolicy = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className='p-9 bg-white h-50'>
        <Row className='align-items-center'>
          <Col xs className='ms-12'>
            <img height={40} src={logo} alt='' />
          </Col>
          <Col xs='auto'>
            <span className='fs-16 fw-normal me-12'>
              <Link to='/auth/registration' className='link-primary fw-bold ms-1'>
                Sign up
              </Link>
            </span>
            {/* <Button
              variant='link'
              className='btn-flush text-primary fs-18 fw-600'
              onClick={() => navigate('/auth')}
            >
              Sing In
            </Button> */}
          </Col>
          <Col xs={12} className='mt-15 mt-md-5 text-center'>
            <h3 className='fs-26 fw-bolder'>Privacy policy.</h3>
          </Col>
        </Row>
        <div className='mt-10'>
          <div className='position-absolute start-0 end-0 w-100 px-18'>
            <Card className='p-5 bg-light-primary'>
              <Card.Body className=' text-dark'>
                {/*  <p className='fs-16 fw-500'>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam eum, ratione
                  sequi commodi numquam officia architecto accusamus alias voluptatum facere
                  eligendi earum ullam? Non veniam error repellat repellendus modi odio id aut
                  doloribus, harum corporis laboriosam dignissimos tempora expedita beatae nemo ad,
                  esse facere vero officiis iste exercitationem quasi unde.
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea maxime delectus culpa
                  facilis aspernatur provident. Animi deleniti dolor illo repellendus voluptas
                  incidunt nostrum quod odio. Debitis, praesentium fuga. Nisi, nemo.
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tenetur repudiandae
                  consequatur deserunt inventore rem, beatae soluta deleniti nam voluptatem, alias
                  similique incidunt, quas ipsum laborum reprehenderit? Doloribus iure accusantium,
                  id at animi modi? Reiciendis sed officiis aliquam voluptate omnis odit tempore
                  eveniet magni quasi ad? Repellendus consectetur aspernatur alias voluptate eius,
                  quidem rem excepturi nisi ad, sapiente, voluptatum non voluptates?
                </p> */}
                <h1 className='fs-22 fw-600'>Privacy Policy for UTrade</h1>
                <p className='fs-16 fw-500'>
                  At UTrade Business, accessible from https://business.utrade.tz, one of our main
                  priorities is the privacy of our visitors. This Privacy Policy document contains
                  types of information that is collected and recorded by UTrade Business and how we
                  use it.
                </p>
                <p className='fs-16 fw-500'>
                  If you have additional questions or require more information about our Privacy
                  Policy, do not hesitate to contact us.
                </p>
                <p className='fs-16 fw-500'>
                  This Privacy Policy applies only to our online activities and is valid for
                  visitors to our website with regards to the information that they shared and/or
                  collect in UTrade Business. This policy is not applicable to any information
                  collected offline or via channels other than this website.
                </p>
                <h2>Consent</h2>
                <p className='fs-16 fw-500'>
                  By using our website, you hereby consent to our Privacy Policy and agree to its
                  terms.
                </p>
                <h2>Information we collect</h2>
                <p className='fs-16 fw-500'>
                  The personal information that you are asked to provide, and the reasons why you
                  are asked to provide it, will be made clear to you at the point we ask you to
                  provide your personal information.
                </p>
                <p className='fs-16 fw-500'>
                  If you contact us directly, we may receive additional information about you such
                  as your name, email address, phone number, the contents of the message and/or
                  attachments you may send us, and any other information you may choose to provide.
                </p>
                <p className='fs-16 fw-500'>
                  When you register for an Account, we may ask for your contact information,
                  including items such as name, company name, address, email address, and telephone
                  number.
                </p>
                <h2>How we use your information</h2>
                <p className='fs-16 fw-500'>
                  We use the information we collect in various ways, including to:
                </p>
                <ul>
                  <li className='fs-16 fw-500'>Provide, operate, and maintain our website</li>
                  <li className='fs-16 fw-500'>Improve, personalize, and expand our website</li>
                  <li className='fs-16 fw-500'>Understand and analyze how you use our website</li>
                  <li className='fs-16 fw-500'>
                    Develop new products, services, features, and functionality
                  </li>
                  <li className='fs-16 fw-500'>
                    Communicate with you, either directly or through one of our partners, including
                    for customer service, to provide you with updates and other information relating
                    to the website, and for marketing and promotional purposes
                  </li>
                  <li className='fs-16 fw-500'>Send you emails</li>
                  <li className='fs-16 fw-500'>Find and prevent fraud</li>
                </ul>
                <h2>Log Files</h2>
                <p className='fs-16 fw-500'>
                  UTrade Business follows a standard procedure of using log files. These files log
                  visitors when they visit websites. All hosting companies do this and a part of
                  hosting services' analytics. The information collected by log files include
                  internet protocol (IP) addresses, browser type, Internet Service Provider (ISP),
                  date and time stamp, referring/exit pages, and possibly the number of clicks.
                  These are not linked to any information that is personally identifiable. The
                  purpose of the information is for analyzing trends, administering the site,
                  tracking users' movement on the website, and gathering demographic information.
                </p>
                <h2>Contact Us</h2>
                <p className='fs-16 fw-500'>
                  If you have any questions or suggestions about our Privacy Policy, do not hesitate
                  to contact us.
                </p>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}
export default PrivacyPolicy
