import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_seller/helpers'

const Error500: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-2qx text-gray-900 mb-4'>Network Error</h1>

      <div className='fw-semibold fs-6 text-gray-500 mb-7'>
        Something went wrong! Please try again later.
      </div>

      <div className='mb-11'>
        <img
          src={toAbsoluteUrl('/media/auth/no-signal.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/auth/500-error-dark.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
    </>
  )
}

export {Error500}
