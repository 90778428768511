import { Chart, registerables } from 'chart.js'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SellerI18nProvider } from './_seller/i18n/Seller18n'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './_seller/assets/sass/plugins.scss'
import './_seller/assets/sass/style.react.scss'
import './_seller/assets/sass/style.scss'
import { AuthProvider } from './app/modules/auth'
import { AppRoutes } from './app/routing/AppRoutes'

Chart.register(...registerables)
const queryClient = new QueryClient()
const container = document.getElementById('root')

if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <SellerI18nProvider>
        <AuthProvider>
          <ToastContainer />
          <AppRoutes />
        </AuthProvider>
      </SellerI18nProvider>
    </QueryClientProvider>
  )
}
