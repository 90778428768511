import clsx from 'clsx'
import {KTSVG} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {useEffect, useState} from 'react'
import BellSVG from '../../../../_seller/assets/media/svg_uTrade/bell-icon.svg'
import {useAuth} from '../../../../app/modules/auth'
import {NOTIFICATION} from '../../../../api/apiEndPoints'
import APICallService from '../../../../api/apiCallService'
const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-40px symbol-md-50px symbol-circle'
const btnIconClass = 'svg-icon-1'
const Navbar = () => {
  const {config} = useLayout()
  const {currentUser} = useAuth()
  const [show, setShow] = useState(false)
  const [count, setCount] = useState(false)
  useEffect(() => {
    ;(async () => {
      await fetchNotificationCount()
    })()
  }, [])
  const fetchNotificationCount = async () => {
    let apiService = new APICallService(
      NOTIFICATION.GETNOTFICATIONCOUNT,
      currentUser?.business.profile
    )
    let response = await apiService.callAPI()
    if (response) {
      setCount(response.unreadItems.length ? true : false)
    }
  }
  const MarkAsRead = async () => {
    if (show === true) {
      let apiService = new APICallService(NOTIFICATION.MARKREAD, null, {
        id: currentUser?.business.profile,
      })
      let response = await apiService.callAPI()
      if (response) {
        await fetchNotificationCount()
      }
    }
  }
  useEffect(() => {
    ;(async () => {
      await MarkAsRead()
    })()
  }, [show])
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={btnClass}
          onClick={() => {
            setShow(!show)
          }}
        >
          <div>
            <KTSVG path={BellSVG} className={btnIconClass} />
            {count ? (
              <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-end top-10 start-400 animation-blink'></span>
            ) : (
              <></>
            )}
          </div>
        </div>
        <HeaderNotificationsMenu show={show} />
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div className='symbol symbol-lg-50px symbol-40px symbol-circle '>
            <div className='symbol-label fs-1 fw-bold bg-light-primary text-primary fw-700'>
              {currentUser?.name.charAt(0).toUpperCase()}
            </div>
          </div>
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}
export {Navbar}
