export const PREF_TOKEN = 'bearerToken'
export const IS_INTRO = '/'
export const IS_LOGIN = 'Login'
// API BASE URL
// export const BASE_URL = 'http://89.233.108.35:9003/api/'
// export const BASE_URL = 'http://127.0.0.1:4000/'
export const BASE_URL = 'https://api.utrade.tz/'
// export const BASE_URL = 'http://api.dev.utrade.imperoserver.in/'
// export const BASE_URL = 'https://api.utrade.imperoserver.in/'
export const PAGE_LIMIT = 10
// API TYPES
export const GET = 'GET'
export const GET_URL_PARAMS = 'GET_URL_PARAMS'
export const GET_ID_PARAMS = 'GET_ID_PARAMS'
export const GET_IDS_PARAMS = 'GET_IDS_PARAMS'
export const GET_URL_ENCODED = 'GET_URL_ENCODED'
export const GET_URL_ID_PARAMS = 'GET_URL_ID_PARAMS'
export const POST = 'POST'
export const POST_ID_PARAMS = 'POST_ID_PARAMS'
export const POST_RAW = 'POST_RAW'
export const POST_FORM = 'POST_FORM'
export const POST_URL_ENCODED = 'POST_URL_ENCODED'
export const POST_URL_PARAMS = 'POST_URL_PARAMS'
export const POST_URL_ENCODED_ID_PARAMS = 'POST_URL_ENCODED_ID_PARAMS'
export const MULTI_PART_POST = 'MULTI_PART'
export const PATCH = 'PATCH'
export const PATCH_ID = 'PATCH_ID'
export const PATCH_FORM = 'PATCH_FORM'
export const PATCH_FORM_ID = 'PATCH_FORM_ID'
export const PATCH_URL_ENCODED = 'PATCH_URL_ENCODED'
export const PATCH_FORM_ID_URL_ENCODED = 'PATCH_FORM_ID_URL_ENCODED'
export const MULTI_PART_ID_POST = 'MULTI_PART'
export const MULTI_PART_ID_PATCH = 'MULTI_PART_PATCH'
export const DELETE = 'DELETE'
export const DELETE_URL_PARAMS = 'DELETE_URL_PARAMS'
export const DELETE_URL_ENCODED = 'DELETE_URL_ENCODED'
export const DELETE_ID_PARAMS = 'DELETE_ID_PARAMS'
//Response
export const ResponseFail = 400
export const ResponseSuccess = 200
export const AuthError = 401
export const Maintenance = 503
//Kyc Status
export const Submitted = 1
export const UnderReview = 2
export const InfoRequired = 3
export const Accepted = 4
export const Rejected = 5
//Product State Enum
export const AllProduct = 1
export const Actived = 2
export const Deactivated = 3
export const VerificationPending = 4
export const ProductRejectedState = 5
//Quantity Enum
export const CartonWithDozens = 1
export const Dozen = 2
export const Piece = 3
export const CartonWithPieces = 4
//Technical Details Enum
export const SingleLineText = 1
export const MultiLineText = 2
export const SingleSelection = 3
export const MultiSelection = 4
export const DateSelection = 5
//Product Status
export const ProductAccepted = 2
export const ProductUnderReview = 3
export const ProductRejected = 4
// Orders Status
export const NewOrder = 1
export const PreparingForDispatch = 2
export const Delivered = 3
export const Cancelled = 4
//Advertisement Type
export const HomePage = 1
export const CategoryPage = 2
//Advertisement Product Type
export const SingleProduct = 1
export const MultipleProduct = 2
//Advertisement State
export const AdvertisementRequest = 1
export const AdvertisementAccepted = 2
export const AdvertisementOngoing = 3
export const AdvertisementUpcoming = 4
export const AdvertisementCompleted = 5
//Advertisement PaymentStatuses
export const AdvertisementPaid = 1
export const AdvertisementUnpaid = 2
//Advertisement PaymentMethods
export const Card = 1
export const Cash = 2
//Inventory Transaction/History
export const ShipmentThroughAdded = 'c1' //New Stock Updated
export const AddedOnReturn = 'c2' //Customer return
export const ManuallyAdded = 'c3' //New Stock Updated
export const DirectlyReceived = 'c4' //New Stock Updated
export const AddedOnOrderCancellation = 'c5' //Order modified
export const AddedOnOrderModification = 'c6' //Order modified
export const DeductedOnSale = 'd1' //New order
export const DeductedOnReturnToSeller = 'd2' //Goods return request
export const ManuallyDeducted = 'd3' //Missing/Damaged goods
export const DeductedOnMissing = 'd4' //Missing/Damaged goods
export const DeductedOnDamage = 'd5' //Missing/Damaged goods
export const DeductedOnOrderModification = 'd6' //Order modified
//Shipment Status
export const ShipmentSubmitted = 1
export const ShipmentAccepted = 2
export const ShipmentCancelled = 3
export const ShipmentRejected = 4
export const ShipmentPickedUp = 5
export const ShipmentDelivered = 6
export const ShipmentCompleted = 7
export const ShipmentPickedUpStarted = 8
//User Types
export const SuperAdmin = 2
export const Seller = 1
//Shipment Type
export const SelfTransport = 1
export const UTradeTransport = 2
//Shipment Fees
export const WarehouseStorage = 1
export const Shipping = 2
//Order Status
export const OrderSubmitted = 1 //New-order-placed
export const OrderProcessed = 2 //Route-planning generated
export const OrderOutForDelivery = 3 //out for delivery
export const OrderDelivered = 4 //Delivered
export const OrderCancelled = 5 //Cancelled
//Tax Types
export const GeneralTax = 1
export const SystemTax = 2
//Order Payment method
export const OrderCash = 1
export const OrderTigoPesa = 2
export const OrderMPesa = 3
export const OrderCard = 4
//Google autocomplete apikey
export const APIkey = 'AIzaSyAg41o-JrOGvOov4uDRb9CzL2AyfUMYDbI'
