export const String = {}
export const Error = {
  NET_ERR: 'Please check your internet connection and try again!',
}
export const Dashboard = {
  businessDetailsReview: 'Business details in review!',
  stayTuned: 'Stay tuned! We’ll verify your details soon.',
  editKyc: 'Edit KYC details',
  welcome: 'Hello ',
  completeBusiness: 'Complete business verification!',
  addKyc:
    'Please add your KYC details to complete your business verification and start selling on uTrade.',
  completeKyc: 'Complete KYC',
  moreInformation: 'We require more information from you!',
  uploadClear: 'Please upload clear picture of TIN Certificate and Business License.',
  congrats: 'Congratulations! Your business details are verified! 😀',
  startSelling: 'You can now start selling!',
  detailsRejected: 'Business details rejected',
  incorrectDocuments: 'Reason: ',
  contactus: 'Contact us',
}
export const contactUs = {
  title: 'Contact Us',
  message:
    'Please send us your feedback, suggestions or queries and we will get back to you by email soon.',
  sendMessage: 'Send Message',
}
export const General = {
  pleaseWait: 'Please wait...',
}
export const Profile = {
  title: 'Manage profile',
  name: 'Name: ',
  businessName: 'Business name:',
  phoneNumber: 'Phone number:',
  email: 'Email:',
  businessAddress: 'Business address:',
  aboutBusiness: 'About the business:',
  businessCategory: 'Business category:',
  businessType: 'Business type:',
  businessDetails: 'Business details',
  businessCertificate: 'Business certificates',
  tinCertificate: 'TIN Certificate',
  businessLicense: 'Business License',
  vatCertificate: 'VAT Certificate',
  foodCertificate: 'Food Category Certif…',
  hygienicCertificate: 'Hygiene Monitored Certif…',
}
