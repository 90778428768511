/* eslint-disable jsx-a11y/anchor-is-valid */
import Select from 'react-select'
const CustomSelect = (props: any) => {
  const customFilterOption = (option: any, rawInput: any) => {
    const label = option.data.title
    const input = rawInput.toLowerCase()
    const text = label.toLowerCase()
    return text.includes(input)
  }
  return (
    <>
     
      <Select
        // menuIsOpen={true}
        onMenuOpen={props.onMenuOpen}
        onMenuClose={props.onMenuClose}
        hideSelectedOptions={props.hideSelectedOptions}
        value={props.value}
        filterOption={customFilterOption}
        menuPosition={'fixed'}
        isClearable={props.isClearable}
        menuPortalTarget={document.body}
        placeholder={props.placeholder}
        defaultValue={props.default}
        isLoading={props.isLoading}
        onChange={props.onChange}
        onInputChange={props.onInputChange}
        onMenuScrollToBottom={props.onMenuScrollToBottom}
        isDisabled={props.disabled}
        options={props.options}
        isSearchable={props.isSearchable}
        styles={{
          option: (base) => ({
            ...base,
            borderBottom: `1px solid #e0e0df`,
            ':last-child': {
              borderBottom: 'none',
            },
            margin: '0px',
            background: 'white',
            padding: '12px 15px 11px',
            ':active': {
              ...base[':active'],
              color: '#1b74e4',
              background: '#f1faff',
            },
            ':hover': {
              ...base[':active'],
              color: '#1b74e4',
              background: '#f1faff',
            },
            color: '#1a1a1a',
            fontSize: '1.154rem',
            fontWeight: '500',
            height: props.minHeight ? props.minHeight : 'auto',
            display: 'flex',
            alignItems: 'center',
          }),
          control: (base) => ({
            ...base,
            border: props.border ? '0.5px solid ' + props.border : '0.5px solid #e0e0df',
            boxShadow: 'none',
            minHeight: props.minHeight ? props.minHeight : '60px',
            height: 'auto',
            minWidth: props.minWidth ? props.minWidth : '220px',
            width: 'auto',
            borderRadius: props.radiusTable ? props.radiusTable : '8px',
            padding: '0px 0px 0px 4px',
            fontSize: '1.231rem',
            fontWeight: '600',
            backgroundColor: props.backgroundColor ? props.backgroundColor : '#f9f9f9',
            ':hover': {
              ...base[':hover'],
              border: props.border ? '0.5px solid ' + props.border : '0.5px solid #e0e0df',
            },
          }),
          menu: (base) => ({
            ...base,
            boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
            color: '#1a1a1a',
          }),
          menuList: (base) => ({
            ...base,
            paddingTop: '0px',
            paddingBottom: '0px',
            borderRadius: '5px',
            width: 'auto',
            color: '#1a1a1a',
          }),
          menuPortal: (base, props) => ({
            ...base,
            zIndex: 9999,
          }),
          indicatorSeparator: (base) => ({
            ...base,
            display: 'none',
          }),
          indicatorsContainer: (base) => ({
            ...base,
            color: '#f9f9f9',
            strokeWidth: '5px',
          }),
          multiValueRemove: (base, {data}) => ({
            ...base,
            height: '12px',
            ':hover': {
              backgroundColor: '#e7f1fd',
            },
            svg: {
              height: '22px',
              width: '22px',
              fill: '#7c7c7c',
            },
          }),
          multiValue: (base) => ({
            ...base,
            backgroundColor: '#e7f1fd',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '11px 14px',
            borderRadius: '6px',
            fontSize: '1.231rem',
            fontWeight: '600',
            height: '42px',
            width: 'auto',
          }),
          multiValueLabel: (base) => ({
            fontSize: '1.231rem',
            fontWeight: '600',
          }),
        }}
        isMulti={props.isMulti}
      />
    </>
  )
}
export {CustomSelect}
