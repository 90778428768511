import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_seller/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_seller/assets/ts/_utils'
import {WithChildren} from '../../_seller/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {KycStepper} from '../modules/auth/kyc-process/KycStepper'
import {WelcomePage} from '../modules/auth/WelcomePage'
import VerifyOTP from '../modules/auth/components/VerifyOTP'
import KycRejected from '../modules/auth/kyc-process/details/kycRejected'
import KycSubmitted from '../modules/auth/kyc-process/details/kycSubmitted'
import {EmptyData} from '../modules/auth/EmptyData'
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile-management/index'))
  const MyProducts = lazy(() => import('../modules/catalogue/my-products/index'))
  const Advertising = lazy(() => import('../modules/advertising/index'))
  const Orders = lazy(() => import('../modules/orders/index'))
  const Inventory = lazy(() => import('../modules/inventory/index'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='/*' element={<Navigate to='/dashboard' />} />
        <Route path='/*' element={<Navigate to='/dashboard/top-selling-product' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='profile-management/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='catalogue/*'
          element={
            <SuspensedView>
              <MyProducts />
            </SuspensedView>
          }
        />
        <Route
          path='advertising/*'
          element={
            <SuspensedView>
              <Advertising />
            </SuspensedView>
          }
        />
        <Route
          path='orders/*'
          element={
            <SuspensedView>
              <Orders />
            </SuspensedView>
          }
        />
        <Route
          path='inventory/*'
          element={
            <SuspensedView>
              <Inventory />
            </SuspensedView>
          }
        />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
      <Route path='kyc-process' element={<KycStepper />} />
      <Route path='welcome' element={<WelcomePage />} />
      <Route path='verify-otp' element={<VerifyOTP />} />
      <Route path='kyc-rejected' element={<KycRejected />} />
      <Route path='kyc-submitted' element={<KycSubmitted />} />
      <Route path='message' element={<EmptyData />} />
    </Routes>
  )
}
const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}
export {PrivateRoutes}
