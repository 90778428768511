import {IKYCUpdate, IKYCFeedback, IKYCRead} from '../../types'
export const KycJson = {
  kycUpdate: ({kycData, currentStep, submitForReview}: IKYCUpdate) => {
    let form = new FormData()
    if (kycData.ownerName !== '') form.append('ownerName', kycData.ownerName)
    if (kycData.name !== '') form.append('name', kycData.name)
    if (kycData.address !== '') form.append('address', kycData.address)
    if (kycData.lat !== '') form.append('lat', kycData.lat)
    if (kycData.lng !== '') form.append('lng', kycData.lng)
    if (kycData.about !== '') form.append('about', kycData.about)
    if (kycData.categories.length) {
      kycData.categories.map((catVal: any, index: number) => {
        return form.append('categories[' + index + ']', catVal)
      })
    }
    if (kycData.imageReader !== '') form.append('image', kycData.imageReader)
    form.append('KYC[uiStep]', currentStep ? currentStep : '0')
    form.append('submitForReview', submitForReview ? submitForReview : 'false')
    if (kycData.businessCertificates.length) {
      kycData.businessCertificates.map((catVal: any, index: number) => {
        if (catVal.reference !== '') {
          form.append('certificates[' + index + '][reference]', catVal.reference)
          form.append('certificates[' + index + '][expiry]', catVal.expiry)
          form.append('certificates[' + index + '][file]', catVal.imageReader)
          return form
        }
      })
    }
    if (kycData.businessTypes.length) {
      kycData.businessTypes.map((businessVal: any, index: number) => {
        return form.append('types[' + index + ']', businessVal)
      })
    }
    // form.forEach((value, key) => {
    //   console.log(key + ' ' + value)
    // })
    return form
  },
  kycFeedback: ({message}: IKYCFeedback) => {
    return {message: message}
  },
  kycRead: ({status}: IKYCRead) => {
    // let form = new FormData()
    // form.append('status', status)
    return {status: status}
  },
}
