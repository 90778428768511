// API END Points
import * as constants from '../utils/constants'
/**---------------Authentication------------------------------ */
export const AUTH = {
  REGISTER: 'seller' + ' ' + constants.POST_RAW,
  LOGIN: 'seller/login' + ' ' + constants.POST_RAW,
  LOGOUT: 'seller/logout' + ' ' + constants.POST_RAW,
  RESEND: 'seller/otp/resend' + ' ' + constants.PATCH,
  VERIFY: 'seller/otp/verify' + ' ' + constants.POST_URL_ENCODED,
  FORGOTPASSWORD: 'seller/password/forgot' + ' ' + constants.POST_URL_ENCODED,
  VERIFYPASSWORD: 'seller/password/code/verify' + ' ' + constants.POST_URL_ENCODED,
  RESETPASSWORD: 'seller/password/reset' + ' ' + constants.POST_URL_ENCODED,
  DASHBOARD: 'seller/dashboard' + ' ' + constants.GET,
  CHANGEPASSWORD: 'seller/password' + ' ' + constants.PATCH_URL_ENCODED,
}
export const KYC = {
  KYCINIT: 'seller/kyc/init' + ' ' + constants.GET_ID_PARAMS,
  KYCUPDATE: 'seller/kyc' + ' ' + constants.MULTI_PART_ID_PATCH,
  KYCFEEDBACK: 'seller/kyc/feedback' + ' ' + constants.MULTI_PART_ID_POST,
  KYCREAD: 'seller/kyc/status/read' + ' ' + constants.PATCH_FORM_ID_URL_ENCODED,
  KYCSTATUSCHECK: 'seller/dashboard/status' + ' ' + constants.GET,
}
export const BUSINESS = {
  GETPROFILE: 'seller/business/profile' + ' ' + constants.GET_ID_PARAMS,
  UPDATEPROFILE: 'seller/business/profile' + ' ' + constants.MULTI_PART_ID_PATCH,
  VERIFYDETAILS: 'seller/business/otp/profile' + ' ' + constants.POST_URL_ENCODED_ID_PARAMS,
  UPDATEBANKDETAILS: 'seller/business/bank-details' + ' ' + constants.PATCH_ID,
  BANKDETAILS: 'seller/business/bank-details' + ' ' + constants.GET_ID_PARAMS,
}
export const DEFAULT = {
  CERTIFICATES: 'seller/default/business/certificate' + ' ' + constants.GET_URL_PARAMS,
  BUSINESSTYPES: 'seller/default/business/types' + ' ' + constants.GET_URL_PARAMS,
  CATEGORIES: 'seller/default/business/categories' + ' ' + constants.GET_URL_PARAMS,
  DISTRICTS: '_default/districts' + ' ' + constants.GET_URL_PARAMS,
}
export const NOTIFICATION = {
  GETNOTFICATION: 'seller/business/notifications' + ' ' + constants.GET_URL_ID_PARAMS,
  GETNOTFICATIONCOUNT: 'seller/business/notifications/count' + ' ' + constants.GET_ID_PARAMS,
  MARKREAD: 'seller/business/notifications' + ' ' + constants.POST_ID_PARAMS,
}
export const PRODUCT = {
  initProduct: '_product/business-products/init' + ' ' + constants.GET_URL_PARAMS,
  initVariant: '_product/business-products/init/variant' + ' ' + constants.GET_ID_PARAMS,
  listProduct: '_product/business-products' + ' ' + constants.GET_URL_ID_PARAMS,
  draftCount: '_product/business-products/draft/count' + ' ' + constants.GET_ID_PARAMS,
  draftList: '_product/business-products/draft' + ' ' + constants.GET_URL_ID_PARAMS,
  deleteProduct: '_product/business-products' + ' ' + constants.DELETE_ID_PARAMS,
  updateProductState:
    '_product/business-products/state/variant' + ' ' + constants.PATCH_FORM_ID_URL_ENCODED,
  productInfo: '_product/business-products/variant' + ' ' + constants.GET_ID_PARAMS,
  addProduct: '_product/business-products' + ' ' + constants.MULTI_PART_ID_POST,
  listVariants: '_product/business-products/list-variants' + ' ' + constants.GET_URL_ID_PARAMS,
  editProduct: '_product/business-products' + ' ' + constants.PATCH_FORM_ID_URL_ENCODED,
  editProductVariant:
    '_product/business-products/variant' + ' ' + constants.PATCH_FORM_ID_URL_ENCODED,
  productExists: '_product/business-products/exists' + ' ' + constants.GET_URL_PARAMS,
}
export const ADVERTISEMENT = {
  FEES: '_product/advertisements/fees' + ' ' + constants.GET,
  ADD: '_product/advertisements' + ' ' + constants.MULTI_PART_ID_POST,
  LIST: '_product/advertisements' + ' ' + constants.GET_URL_ID_PARAMS,
  PAY: '_product/advertisements/payment' + ' ' + constants.POST_ID_PARAMS,
  DETAILS: '_product/advertisements/info' + ' ' + constants.GET_ID_PARAMS,
}
export const inventory = {
  list: '_product/shipments' + ' ' + constants.GET_URL_ID_PARAMS,
  addCostInfo: '_product/shipments/cost' + ' ' + constants.POST_ID_PARAMS,
  init: '_product/shipments/init' + ' ' + constants.POST_ID_PARAMS,
  sendShipmentRequest: '_product/shipments' + ' ' + constants.POST_ID_PARAMS,
  info: '_product/shipments/info' + ' ' + constants.GET_ID_PARAMS,
  infoInventory: '_product/shipments/completion-init' + ' ' + constants.GET_ID_PARAMS,
  updateStatus: '_product/shipments/status' + ' ' + constants.PATCH_ID,
  listInventoryProduct: '_product/inventory/v2/list' + ' ' + constants.GET_URL_ID_PARAMS,
  inventoryStats: '_product/inventory/stats' + ' ' + constants.GET_ID_PARAMS,
  listInventoryVarianTransaction:
    '_product/inventory/transactions' + ' ' + constants.GET_URL_ID_PARAMS,
  variantStockList: '_product/inventory/in-stock' + ' ' + constants.GET_ID_PARAMS,
  variantUpdateInit: '_product/inventory/update-init' + ' ' + constants.GET_ID_PARAMS,
  variantUpdate: '_product/inventory/update' + ' ' + constants.PATCH_ID,
  variantExpiredStockInfo:
    '_product/inventory/expiring-stock/info' + ' ' + constants.GET_URL_PARAMS,
  variantExpiredStockList:
    '_product/inventory/expiring-stock/list' + ' ' + constants.GET_URL_PARAMS,
  variantRemainder: '_product/inventory/reminder' + ' ' + constants.POST_URL_ENCODED_ID_PARAMS,
}
export const ordersDelivery = {
  info: '_product/business-orders/info' + ' ' + constants.GET_IDS_PARAMS,
  list: '_product/business-orders/list' + ' ' + constants.GET_URL_ID_PARAMS,
  variant: '_product/business-orders/count' + ' ' + constants.GET_URL_ID_PARAMS,
  invoiceDownload: '_product/business-reports/orders/invoice' + ' ' + constants.GET_IDS_PARAMS,
}
export const dashboard = {
  salesReport: '_product/business-orders/sales-report' + ' ' + constants.GET_URL_ID_PARAMS,
  orderReport: '_product/business-orders/orders-report' + ' ' + constants.GET_URL_ID_PARAMS,
  topSellingProducts:
    '_product/business-orders/top-selling-products' + ' ' + constants.GET_URL_ID_PARAMS,
}
