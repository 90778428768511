import {useEffect, useState} from 'react'

import CloseGray from '../../../../../_seller/assets/media/svg_uTrade/cross-rounded-gray.svg'
import ShopSvg from '../../../../../_seller/assets/media/svg_uTrade/shop.svg'
import clsx from 'clsx'
import {Col} from 'react-bootstrap'
import ImgCropModal from '../../../../modals/ImgCropModal'

const Step4 = (props: any) => {
  const [showImgCropModal, setShowImgCropModal] = useState(false)
  const [image, setImage] = useState<any>(props.image)
  const [imageReader, setImageReader] = useState()

  const handleModalOpen = (event: any) => {
    const selectedFiles = event.target.files

    if (!selectedFiles) return
    else {
      setImage(URL.createObjectURL(selectedFiles?.[0]))
      setImageReader(selectedFiles?.[0])
    }
    setShowImgCropModal(true)
  }

  const handleChange = (event: any, data: any) => {
    // const selectedFiles = event.target.files
    // if (!selectedFiles) return
    // else {
    //   setImage(URL.createObjectURL(selectedFiles?.[0]))
    //   setImageReader(selectedFiles?.[0])
    // }

    setImage(data)
    setImageReader(event)
    setShowImgCropModal(false)
  }

  useEffect(() => {
    props.onImageChange(image, imageReader)
  }, [image])

  return (
    <>
      {showImgCropModal ? (
        <ImgCropModal
          show={showImgCropModal}
          onHide={() => {
            setImage('')
            setImageReader(undefined)
            setShowImgCropModal(false)
          }}
          image={image}
          onChange={handleChange}
          imageReader={imageReader}
        />
      ) : (
        ''
      )}
      <div className='kyc-process'>

        <div className='mb-9 text-center'>
          <h2 className={clsx('d-flex justify-content-center fs-34 fw-bolder text-dark mb-3')}>
            Please upload your business logo
          </h2>
          <div className='fs-18 fw-500 text-gray'>
            Please upload a logo which in Square format and high quality.
          </div>
        </div>

        <div className='mb-10 fv-row'>
          <div className='row justify-content-center' data-kt-buttons='true'>
            {props.image ? (
              <Col lg={4} md={6}>
                <div className='min-w-lg-375px min-h-lg-375px min-h-275px border border-r10px'>
                  <div className='position-relative'>
                    <img
                      className='img-fluid w-100 min-w-lg-275 min-h-lg-275 min-h-275px'
                      src={props.image}
                      alt=''
                    />
                    <img
                      className='close-top'
                      src={CloseGray}
                      alt=''
                      onClick={(): void => props.onImageDiscard()}
                      style={{cursor: 'pointer'}}
                    />
                  </div>
                </div>
              </Col>
            ) : (
              <Col lg={4} md={6} className='text-center'>
                <div className='cursor-pointer'>
                  <div className='upload-btn-wrapper border border-r10px d-flex flex-center mx-auto min-w-lg-375px min-h-lg-375px min-h-275px  w-100 p-12'>
                    <input
                      type='file'
                      name='myfile'
                      className='w-100 h-100'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                        handleModalOpen(e)
                      }
                    />
                    <span className='d-flex flex-column align-items-center'>
                      <span className='symbol symbol-60px'>
                        <img
                          className='img-fluid'
                          src={props.image ? props.image : ShopSvg}
                          alt=''
                        />
                      </span>
                      <div className='d-flex flex-column mt-3'>
                        <span className='fs-16 fw-600'>Click to upload</span>
                        <span className='fs-14 fw-normal text-gray'>Maximum file size: 5 MB</span>
                      </div>
                    </span>
                  </div>
                </div>
              </Col>
            )}
            {/* After Upload */}
          </div>
        </div>
      </div>
    </>
  )
}

export {Step4}
