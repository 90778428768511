import { Field } from 'formik'
import clsx from 'clsx'
import checkBlue from '../../../../../_seller/assets/media/svg_uTrade/check-blue-rounded.svg'

const Step2 = (props: any) => {

  return (

    <div className='kyc-process'>

      <div className='mb-9 text-center'>
        <h2 className={clsx('d-flex justify-content-center fs-34 fw-bolder text-dark mb-3')}>
          Please select your business category
        </h2>
        <div className='fs-18 fw-500 text-gray'>You can select multiple categories.</div>
      </div>

      <div className='mb-10 fv-row'>
        <div className='row gy-6' data-kt-buttons='true'>
          {props.categories.length > 0 ? (
            props.categories.map((catVal: any, index: number) => {
              return (
                <div className='col-lg-3 min-w-275px'>
                  <Field
                    type='radio'
                    className='btn-check'
                    name='accountTeamSize'
                    value='1-1'
                    id='kt_account_team_size_select_1'
                  />
                  <label
                    className='btn btn-outline btn-outline-default border-r10px w-100 px-4'
                    htmlFor='kt_account_team_size_select_1'
                  >
                    <span
                      className='d-flex align-items-center me-2'
                      onClick={() => {
                        props.onCategorySelect(catVal._id, index)
                      }}
                    >
                      <span className='symbol symbol-circle symbol-40px me-3'>
                        <img
                          src={
                            props.selectedCategories.includes(catVal._id) ? checkBlue : catVal.image
                          }
                          alt={catVal.title}
                        />
                      </span>
                      <span className='d-flex flex-column'>
                        <span
                          className={clsx(
                            'fs-16 fw-600',
                            props.selectedCategories.includes(catVal._id) ? 'text-primary' : ''
                          )}
                        >
                          {catVal.title}
                        </span>
                      </span>
                    </span>
                  </label>
                </div>
              )
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export { Step2 }

