import {IListCategory, IListGeneral} from '../../types'

export const defaultJson = {
  listCategory: ({
    pageNo,
    limit,
    sortKey,
    sortOrder,

    categoriesDepth,
  }: IListCategory) => {
    return {
      pageNo: pageNo,
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: pageNo === 1 ? true : false,
      categoriesDepth: categoriesDepth,
    }
  },
  listGeneral: ({pageNo, limit, sortKey, sortOrder}: IListGeneral) => {
    return {
      pageNo: pageNo,
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: pageNo === 1 ? true : false,
    }
  },
}
