import clsx from 'clsx'
import {Field} from 'formik'

// Media
import RoundedCheckBlue from '../../../../../_seller/assets/media/svg_uTrade/check-blue-rounded.svg'
import RoundedCheckGray from '../../../../../_seller/assets/media/svg_uTrade/check-gray-rounded.svg'

const Step3 = (props: any) => {
  return (
    <div className='kyc-process'>

      <div className='mb-9 text-center'>
        <h2 className={clsx('d-flex justify-content-center fs-34 fw-bolder text-dark mb-3')}>
          Please select your business type
        </h2>
      </div>

      <div className='mb-10 fv-row'>
        <div className='row gy-6' data-kt-buttons='true'>
          {props.businessTypes ? (
            props.businessTypes.map((val: any, index: number) => {
              return (
                <>
                  <div
                    className='col-lg-3 col-md-6'
                    onClick={() => {
                      props.onBusinessTypesSelect(val._id, index)
                    }}
                  >
                    <Field
                      type='radio'
                      className='btn-check'
                      name='accountTeamSize'
                      value='1-1'
                      id='kt_account_team_size_select_1'
                    />
                    <label
                      className='btn btn-outline btn-outline-default border-r10px position-relative w-100 p-12'
                      htmlFor='kt_account_team_size_select_1'
                    >
                      <div className='position-absolute top-0 end-0 me-5 mt-5'>
                        <img
                          className='img-fluid'
                          width={50}
                          height={50}
                          src={
                            props.selectedBusinessTypes.includes(val._id)
                              ? RoundedCheckBlue
                              : RoundedCheckGray
                          }
                          alt=''
                        />
                      </div>
                      <span className='d-flex flex-column align-items-center'>
                        <span className='symbol symbol-circle symbol-100px'>
                          <img className='img-fluid' src={val.image} alt='' />
                        </span>
                        <span className='d-flex flex-column mt-3'>
                          <span className='fs-22 fw-600'>{val.title}</span>
                        </span>
                      </span>
                    </label>
                  </div>
                </>
              )
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export {Step3}
