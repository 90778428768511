/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../helpers';
import { useLayout } from '../../core'
import { Header } from './Header'
import { Navbar } from './Navbar'
import BrandLogo from '../../../../_seller/assets/media/uTradeLogo.png'
import { useThemeMode } from '../../../partials';
import { useAuth } from '../../../../app/modules/auth';

export function HeaderWrapper() {
  const { config, classes } = useLayout()
  const { mode } = useThemeMode();
  const { currentUser } = useAuth();
  if (!config.app?.header?.display) {
    return null
  }

  return (
    <>
      <div id='kt_app_header' className='app-header'>
        <div id='kt_app_header_container' className={clsx('app-container flex-lg-grow-1 w-100',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )} style={{ width: '100%' }}
        >
          {config.app.sidebar?.display && (
            <>
              <div
                className='d-flex align-items-center d-lg-none ms-n2 me-2'
                title='Show sidebar menu'
              >
                <div
                  className='btn btn-icon btn-active-color-primary w-35px h-35px' id='kt_app_sidebar_mobile_toggle'
                >
                  <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className=' svg-icon-1' />
                </div>
                <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                  <Link to='/dashboard' className='d-lg-none'>
                    <img
                      className='img-fluid'
                      height={37}
                      width={131}
                      src={BrandLogo}
                      alt='BrandLogo'
                    />
                  </Link>
                </div>
              </div>
            </>
          )}
          {!config.app.sidebar?.display && (
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
              {currentUser && (
                <div
                  className="btn btn-icon btn-active-icon-primary ms-n2 me-2 d-flex d-lg-none"
                  id="kt_app_sidebar_mobile_toggle"
                >
                  <i className="bi bi-list fs-40"></i>
                </div>
              )}
              <Link to='/dashboard'>
                {config.layoutType !== 'dark-header' ? (
                  <img
                    className='img-fluid'
                    height={37}
                    width={131}
                    src={BrandLogo}
                    alt='BrandLogo'
                  />
                ) : (
                  <>
                    <img
                      className='img-fluid'
                      height={37}
                      width={131}
                      src={BrandLogo}
                      alt='BrandLogo'
                    />
                  </>
                )}
              </Link>
            </div>
          )}
          <div
            id='kt_app_header_wrapper'
            className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
          >
            {config.app.header.default?.content === 'menu' &&
              config.app.header.default.menu?.display && (
                <div
                  className='app-header-menu app-header-mobile-drawer align-items-stretch'
                  data-kt-drawer='true'
                  data-kt-drawer-name='app-header-menu'
                  data-kt-drawer-activate='{default: true, lg: false}'
                  data-kt-drawer-overlay='true'
                  data-kt-drawer-width='225px'
                  data-kt-drawer-direction='end'
                  data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                  data-kt-swapper='true'
                  data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                  data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
                >
                  <Header />
                </div>
              )}
            <Navbar />
          </div>
        </div>
      </div>
    </>
  )
}
